<mat-icon
  (click)="togglePanel()"
  [matBadge]="unreadNotifications.length === 0 ? null : unreadNotifications.length"
  class="notifications-icon"
>
  circle_notifications</mat-icon
>

<div *ngIf="isPanelOpen" class="notification-panel">
  <ng-container
    *ngIf="
      currentNotifications.length > 0 || unreadNotifications.length > 0;
      else noNotificationsBlock
    "
  >
    <mat-tab-group
      mat-align-tabs="center"
      mat-stretch-tabs
      class="notification-tabs"
      [disablePagination]="true"
      (selectedIndexChange)="selectedTabIndex = $event"
    >
      <mat-tab label="Nuevos">
        <div class="notifications-container">
          <div
            *ngFor="let notification of unreadNotifications; let i = index"
            (click)="acknowledgeNotification(notification.id)"
          >
            <div fxLayout="row" fxLayoutAlign="space-between center" class="notification">
              <div fxLayout="column">
                <div fxLayout="row">
                  <span class="font-md">
                    <b class="font-md-bold">{{ notification.title }}</b>
                    {{ notification.content }}</span
                  >
                </div>
                <div>
                  <span class="notification__time">{{
                    notification.createdAt | elapsedTime | titlecase
                  }}</span>
                </div>
              </div>
              <div class="notification-action-icon">
                <mat-icon>check</mat-icon>
              </div>
            </div>
            <mat-divider *ngIf="i + 1 !== unreadNotifications.length"></mat-divider>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Leídas">
        <div class="notifications-container">
          <div
            *ngFor="let notification of currentNotifications; let i = index"
            (click)="removeNotification(notification.id)"
          >
            <div fxLayout="row" fxLayoutAlign="space-between center" class="notification">
              <div fxLayout="column">
                <div>
                  <b>{{ notification.title }}</b>
                </div>
                <div>{{ notification.content }}</div>
                <div>
                  <span class="notification__time">{{
                    notification.createdAt | elapsedTime | titlecase
                  }}</span>
                </div>
              </div>
              <div class="notification-action-icon">
                <mat-icon>cancel</mat-icon>
              </div>
            </div>
            <mat-divider *ngIf="i + 1 !== currentNotifications.length"></mat-divider>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div fxLayout="row" fxLayoutAlign="center center" class="p-8">
      <button
        class="button button--secondary"
        (click)="cleanNotifications()"
        *ngIf="selectedTabIndex ? currentNotifications.length : unreadNotifications.length"
        [disabled]="isProcessing"
      >
        <span *ngIf="!isProcessing; else loadingSpinnerBlock">Limpiar</span>
        <ng-template #loadingSpinnerBlock>
          <div class="processing-spinner"></div>
        </ng-template>
      </button>
    </div>
  </ng-container>
  <ng-template #noNotificationsBlock>
    <p>Sin notificaciones</p>
  </ng-template>
</div>
