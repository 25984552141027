import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IUserNotification, UserNotificationStatus } from '@webtronic-labs/contracts-hip-hip';
import { notificationsFeatureKey, INotificationsState } from './notifications-state.reducer';

export const selectNotificationsState =
  createFeatureSelector<INotificationsState>(notificationsFeatureKey);

export const selectNotifications = createSelector(
  selectNotificationsState,
  (notificationsState) => notificationsState.currentNotifications
);

export const selectUnreadNotifications = createSelector(
  selectNotificationsState,
  (notificationsState) =>
    notificationsState.currentNotifications.filter((notification: IUserNotification) => {
      return notification.status === UserNotificationStatus.Sent;
    })
);

export const selectReadNotifications = createSelector(
  selectNotificationsState,
  (notificationsState) =>
    notificationsState.currentNotifications.filter((notification: IUserNotification) => {
      return notification.status !== UserNotificationStatus.Sent;
    })
);
