<div fxLayout="column" fxLayoutAlign="center start" class="attach-documents-container">
  <div fxLayout="row" fxLayoutAlign="center center" class="attach-title" fxLayoutGap="1rem">
    <mat-icon class="rotate-icon"> attach_file </mat-icon>
    <h5>{{ title }}</h5>
  </div>
  <div class="p-8 w-full" fxLayout="column" fxLayoutGap="1rem">
    <div
      fxLayout="column"
      fxLayoutGap="1rem"
      *ngFor="let userFileToUpload of userFilesToUpload"
      class="w-full"
    >
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="2rem">
        <div fxLayout="column">
          <div fxLayout="row wrap" fxLayoutGap="1rem">
            <h5 [ngClass]="{ 'error-message': userFileToUpload.hasRequiredError }">
              <label required *ngIf="userFileToUpload.isRequired"></label
              >{{ userFileToUpload.title }}
            </h5>
            <h5
              [ngClass]="{ 'error-message': userFileToUpload.hasSizeError }"
              *ngIf="userFileToUpload.hasSizeError"
            >
              Peso máximo excedido {{ roundFileSize(userFileToUpload?.file?.size) }}
            </h5>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="1rem"
              *ngIf="userFileToUpload.file"
            >
              <p class="text-blue">{{ userFileToUpload.file.name }}</p>

              <button class="u-bg-transparent" (click)="removeAttachment(userFileToUpload)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
          <div fxLayout="row">
            <span class="description">{{ userFileToUpload.description }}</span>
          </div>
        </div>

        <button
          type="button"
          class="button"
          [ngClass]="{ 'button--secondary': !userFileToUpload.file }"
          (click)="uploadFile(userFileToUpload)"
        >
          {{ !userFileToUpload.file ? 'Subir' : 'Cambiar' }}
        </button>
      </div>
    </div>
  </div>
</div>
<form>
  <input
    type="file"
    accept=".pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.jpg,.jpeg,.png"
    fxHide
    #fileInput
    (change)="uploadFilesStatusChange($event)"
  />
</form>
