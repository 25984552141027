import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FirebaseService } from '../../../../projects/hip-lib/src/public-api';
import { environment } from '../../../environments/environment';

export const InterceptorIgnoreHeader = 'X-Ignore-Interceptor';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private firebaseService: FirebaseService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authorizedEndpoints: string[] = [environment.apiUrl];

    const isCallingAuthorizedEndpoint = !!authorizedEndpoints.find((endpoint: string) => {
      return req.url.indexOf(endpoint) !== -1;
    });

    // Allow to ignore headers, in case of using 3d parties
    if (!isCallingAuthorizedEndpoint || req.headers.has(InterceptorIgnoreHeader)) {
      const newHeaders = req.headers.delete(InterceptorIgnoreHeader);

      return next.handle(req.clone({ headers: newHeaders }));
    }

    return this.firebaseService.getAuthHeaders().pipe(
      switchMap((headersWithToken: HttpHeaders) => {
        const copiedReq: HttpRequest<any> = req.clone({
          headers: headersWithToken,
        });

        return next.handle(copiedReq);
      })
    );
  }
}
