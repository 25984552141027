import { createAction, props } from '@ngrx/store';
import { IBankInformationStatus } from '../core';

// ------------------- Main ------------------- //

export const toggleSelectBank = createAction(
  '[BanksDetails] toggle the select status of the bank name',
  props<{ bankName: string }>()
);

export const setBanksStatuses = createAction(
  '[BanksDetails] Set the banksStatuses array',
  props<{ banksStatuses: IBankInformationStatus[] }>()
);

export const setBankPaymentTypes = createAction(
  '[BanksDetails] Set the bankPaymentTypes array',
  props<{ bankPaymentTypes: string[] }>()
);

export const setIsEditMode = createAction(
  '[BanksDetails] Set the isEditMode',
  props<{ isEditMode: boolean }>()
);
