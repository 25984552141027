import { createAction, props } from '@ngrx/store';

// ------------------- Main ------------------- //

export const setVersion = createAction(
  '[AppRoot] Set current version',
  props<{ version: string }>()
);

export const setIsFullScreenLoading = createAction(
  '[AppRoot] Set full screen loader',
  props<{ isLoading: boolean }>()
);

export const setIsLoadingCurrentStep = createAction(
  '[AppRoot] Set is loading current step',
  props<{ isLoading: boolean }>()
);
