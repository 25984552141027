import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs';
import { selectVersion } from '../../../store-app-root/app-root.selectors';

@Component({
  selector: 'lib-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input()
  public isProduction = false;
  @Input()
  termAndConditionsRoute: string[] = ['terms-and-conditions'];
  privacyPolicyRoute: string[] = ['privacy-policy'];
  public currentAppVersion = 'DEV';

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.getAppVersion();
  }

  private getAppVersion() {
    this.store.pipe(select(selectVersion), take(1)).subscribe({
      next: (version: string) => {
        this.currentAppVersion = version;
      },
    });
  }
}
