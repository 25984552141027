import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  notificationsFeatureKey,
  notificationsReducer,
} from './notifications-store/notifications-state.reducer';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NotificationsPanelComponent } from './notifications-button/notifications-panel.component';
import { MatDividerModule } from '@angular/material/divider';
import { ElapsedTimeModule } from '../pipes/elapsed-time/elapsed-time.module';
import { HipLoadersModule } from '../loaders/loaders.module';

@NgModule({
  declarations: [NotificationsPanelComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature(notificationsFeatureKey, notificationsReducer),
    MatBadgeModule,
    MatIconModule,
    FlexLayoutModule,
    MatTabsModule,
    MatDividerModule,
    ElapsedTimeModule,
    HipLoadersModule,
  ],
  exports: [NotificationsPanelComponent],
})
export class NotificationsModule {}
