import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PrivacyPolicyPublicComponent } from 'projects/hip-lib/src/lib/public-views/privacy-policy-public/privacy-policy-public.component';
import { TermsAndConditionsPublicComponent } from 'projects/hip-lib/src/lib/public-views/terms-and-conditions-public/terms-and-conditions-public.component';
import { FirebaseLoggedIn } from '../../projects/hip-lib/src/public-api';

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('../../projects/hip-lib/src/public-api').then((m) => m.AuthModule),
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsPublicComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyPublicComponent,
  },
  {
    path: '',
    loadChildren: () => import('./app-root/app-root.module').then((m) => m.AppRootModule),
    canActivate: [FirebaseLoggedIn],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
