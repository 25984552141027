import { DocumentUploadType } from '@webtronic-labs/contracts-hip-hip';

export interface IDocumentToUpload {
  file: File;
  documentType: DocumentUploadType;
  title: string;
  hasRequiredError?: boolean;
  hasSizeError?: boolean;
  isRequired?: boolean;
  description?: string;
  documentFormat?: string;
}
