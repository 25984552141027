import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IUserNotification } from '@webtronic-labs/contracts-hip-hip';
import { FirebaseService } from '../../../firebase';

@Component({
  selector: 'hip-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Output()
  public clickGoBack = new EventEmitter<void>();
  @Output()
  public clickAdvance = new EventEmitter<void>();
  @Output()
  public notificationClicked = new EventEmitter<IUserNotification>();

  @Input()
  public hasNavigationControls = false;
  @Input()
  public canAdvance = true;
  @Input()
  public canGoBack = false;

  constructor(private firebaseService: FirebaseService, private router: Router) {}

  public showTerms() {
    this.router.navigate(['/view', 'terms-and-conditions']);
  }

  public logout() {
    this.firebaseService.logout();
    this.router.navigate(['/auth']);
  }

  public goBackClicked() {
    if (this.canGoBack) {
      this.clickGoBack.emit();
    }
  }

  public advanceClicked() {
    if (this.canAdvance) {
      this.clickAdvance.emit();
    }
  }
}
