import { IUserUploadedDocument } from '../interfaces';
import { getNameFromUrl } from './name-from-url';

export const isImageDocument = (document: IUserUploadedDocument): boolean => {
  const imagesExtensions = ['jpg', 'jpeg', 'png', 'bmp'];
  const name: string = getNameFromUrl(document.url);
  const extension = name.split('.').pop();
  const isImageDocument: boolean = imagesExtensions.includes(extension);

  return isImageDocument;
};
