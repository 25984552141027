<div class="w-full h-full" fxLayout="column" fxLayoutAlign="end center">
  <footer class="footer" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutGap="1rem">
      <a class="font-bold bold-link" [routerLink]="termAndConditionsRoute"
        >Términos & Condiciones</a
      >
      <a class="font-bold bold-link" [routerLink]="privacyPolicyRoute">Política de Privacidad</a>
    </div>
    <p class="font-bold" [ngClass]="{ 'u-bg-warn': !isProduction }">{{ currentAppVersion }}</p>
  </footer>
</div>
