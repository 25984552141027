import { createFeatureSelector, createSelector } from '@ngrx/store';
import { appRootFeatureKey, IAppRootState } from './app-root-state.reducer';

export const selectAppRootState = createFeatureSelector<IAppRootState>(appRootFeatureKey);
export const selectVersion = createSelector(
  selectAppRootState,
  (appRootState) => appRootState.version
);

export const selectIsFullScreenLoading = createSelector(
  selectAppRootState,
  (appRootState) => appRootState.isFullScreenLoading
);

export const selectIsLoadingCurrentStep = createSelector(
  selectAppRootState,
  (appRootState) => appRootState.isLoadingCurrentStep
);
