<lib-info-block type="help">
  <div fxLayout="row" fxLayoutAlign="start center" class="font-sm">
    <span class="help-icon">?</span>
    <span class="ml-4"> Tienes dudas, contacta a tu NEEP</span>
    <a
      fxLayout="row"
      fxLayoutAlign="center center"
      class="ml-4"
      [href]="whatsAppSendMessageApi + whatsappNumber"
      target="_blank"
    >
      <img src="/assets/icons/whatsapp.svg" class="whatsapp-icon" />
    </a>
  </div>
</lib-info-block>
