import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { emailValidator, FirebaseAuthErrorType } from '@webtronic-labs/contracts-hip-hip';
import { from, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { LocalStorageKeys, FirebaseErrorMessages } from '../../core/enums';
import { FirebaseService } from '../../firebase';
import firebase from 'firebase/app';
import { AppLoadingService } from '../../core/services/app-loading.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  public resultErrorMessage = '';
  public isNeepLogin = false;
  public shouldShowPassword = false;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private appLoadingService: AppLoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private firebaseService: FirebaseService
  ) {}

  public ngOnInit() {
    this.initializeLoginForm();
    this.checkIfAlreadyLoggedIn();
    this.checkIfUserIsNeep();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public submitLoginForm() {
    this.resultErrorMessage = '';
    this.appLoadingService.setFullScreenLoading(true);

    const email: string = this.loginForm.get('email').value;
    const password: string = this.loginForm.get('password').value;

    this.firebaseService
      .login(email, password)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.router.navigate(['/']);
        },
        error: (error: firebase.auth.Error) => {
          this.appLoadingService.setFullScreenLoading(false);
          if (
            error.code === FirebaseAuthErrorType.UserNotFound ||
            error.code === FirebaseAuthErrorType.WrongPassword
          ) {
            this.resultErrorMessage = FirebaseErrorMessages.UserNotFoundOrWrongPassword;

            return;
          }

          this.resultErrorMessage = error.message;
        },
      });
  }

  public hideTermsAndConditions(): void {
    localStorage.setItem(LocalStorageKeys.AcceptedTerms, 'false');
  }

  public togglePasswordVisibility() {
    this.shouldShowPassword = !this.shouldShowPassword;
  }

  private initializeLoginForm() {
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.pattern(emailValidator)]),
      password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
    });
  }

  public get emailControl(): AbstractControl {
    return this.loginForm.get('email');
  }

  public get passwordControl(): AbstractControl {
    return this.loginForm.get('password');
  }

  private checkIfAlreadyLoggedIn() {
    from(this.firebaseService.isLoggedIn())
      .pipe(take(1))
      .subscribe({
        next: (isLoggedIn: boolean) => {
          if (isLoggedIn) {
            this.router.navigate(['/']);

            return;
          }

          this.appLoadingService.setFullScreenLoading(false);
        },
      });
  }

  private checkIfUserIsNeep() {
    this.isNeepLogin =
      this.route.snapshot.data && this.route.snapshot.data['isNeepProject'] === true;
  }
}
