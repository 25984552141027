import { createReducer, on } from '@ngrx/store';
import * as DashboardActionTypes from './firebase.actions';
import firebase from 'firebase/app';

export const firebaseFeatureKey = 'firebaseState';

export interface IFirebaseState {
  user: firebase.User;
}

export const firebaseInitialState: IFirebaseState = {
  user: null,
};

export const firebaseReducer = createReducer(
  firebaseInitialState,
  on(DashboardActionTypes.setFirebaseUser, (state: IFirebaseState, action) => {
    return { ...state, user: action.user };
  })
);
