import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElapsedTimePipe } from './elapsed-time.pipe';

@NgModule({
  declarations: [ElapsedTimePipe],
  imports: [CommonModule],
  exports: [ElapsedTimePipe],
})
export class ElapsedTimeModule {}
