import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullScreenLoaderComponent } from './full-screen-loader/full-screen-loader.component';
import { MiniLoaderComponent } from './mini-loader/mini-loader.component';
import { ProcessingSpinnerComponent } from './processing-spinner/processing-spinner.component';

const components = [FullScreenLoaderComponent, MiniLoaderComponent, ProcessingSpinnerComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, FlexLayoutModule],
  exports: [...components],
})
export class HipLoadersModule {}
