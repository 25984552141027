<hip-modal
  [routeToGoOnBackdropClick]="'/auth'"
  [canBeClosed]="true"
  (modalClosed)="navigateToAuth()"
  *ngIf="!wasEmailSent"
>
  <div class="pass-recovery-container">
    <h3>Recuperar contraseña</h3>
    <p class="mt-4">A continuación provee tu dirección de correo electrónico</p>
    <p class="mb-4">En tu bandeja de entrada encontrarás un enlace para continuar</p>

    <form [formGroup]="passwordRecoveryForm" (ngSubmit)="submitRecoveryForm()">
      <div class="form-field">
        <label for="email">Correo Electrónico</label>
        <input
          formControlName="email"
          class="form-field__input"
          type="text"
          name="email"
          placeholder="Correo Electrónico"
        />

        <p *ngIf="!emailControl.valid && emailControl.touched" class="form-field__error font-md">
          Introduce un email válido
        </p>

        <p *ngIf="wasEmailSent" class="font-md mt-4">
          El correo de recuperación de contraseña se ha enviado a su bandeja de entrada.
        </p>

        <p *ngIf="hasOcurredError" class="form-field__error font-md">
          {{ firebaseErrorMessages.UserNotFound }}
        </p>
      </div>

      <button
        type="submit"
        class="button"
        [disabled]="passwordRecoveryForm.invalid || isProcessing"
      >
        Enviar
      </button>
    </form>
  </div>
</hip-modal>

<hip-modal type="fixed" *ngIf="wasEmailSent">
  <div
    fxLayout="column"
    class="confirmation-modal"
    fxLayoutGap="3rem"
    fxLayoutAlign="center center"
  >
    <span class="px-8 mt-8 text-center"
      >Se ha enviado un correo a su bandeja de entrada para recuperar su contraseña</span
    >
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2rem" class="mb-8">
      <button class="button" [routerLink]="['/auth']">
        <span class="px-4">Aceptar</span>
      </button>
    </div>
  </div>
</hip-modal>
