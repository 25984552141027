import { EventEmitter, Input, Output } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'hip-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input()
  public hasInsideRadius = false;
  @Input()
  public routeToGoOnBackdropClick = null;
  @Input()
  public canBeClosed = false;

  @Output()
  public modalClosed = new EventEmitter();
  @Output()
  public clickOutside = new EventEmitter();

  public outsideClickEvent() {
    this.clickOutside.emit();
  }

  public closeModal() {
    this.modalClosed.emit();
  }
}
