<div fxLayout="column" fxLayoutAlign="start center">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="w-full cursor-pointer pointer expanded-header px-8 py-4 bg-white"
    (click)="toggle()"
  >
    <div>
      <ng-content select="[header]"></ng-content>
    </div>

    <mat-icon *ngIf="isExpanded; else expandIcon" class="expanded-icons">remove</mat-icon>
    <ng-template #expandIcon>
      <mat-icon class="expanded-icons">add</mat-icon>
    </ng-template>
  </div>
  <div class="px-8 py-4 w-full" *ngIf="isExpanded">
    <ng-content></ng-content>
  </div>
</div>
