import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CurrencyFormatterDirective } from './currency-formatter.directive';
import { NoSpacesDirective } from './no-spaces.directive';

const directives = [NoSpacesDirective, CurrencyFormatterDirective];

@NgModule({
  declarations: [...directives],
  imports: [CommonModule],
  exports: [...directives],
})
export class InputDirectivesModule {}
