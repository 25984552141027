import {
  Directive,
  HostListener,
  Self,
  OnDestroy,
  AfterViewInit,
  Input,
  OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// this directive need to be applied to input tag and format the number write in that input ex: 111102132400.235 -> 1,11,10,21,32,400.24
// when we select the input for modify then we unformat to facilitate the modification, 1,11,10,21,32,400.24 -> 111102132400.24
@Directive({
  selector: '[hipCurrencyFormatter]',
})
export class CurrencyFormatterDirective implements AfterViewInit, OnDestroy, OnInit {
  @Input()
  public maxDecimalValueLength = 2;
  @Input()
  public maxIntergerValueLength = 6;

  private formatter: Intl.NumberFormat;
  private destroy$ = new Subject<void>();

  constructor(@Self() private ngControl: NgControl) {
    this.formatter = new Intl.NumberFormat('en-GB', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }

  @HostListener('focus')
  public onFocus() {
    if (!this.ngControl.value) {
      return;
    }

    this.setCtrlValue(this.unformatValue(this.ngControl.value));
  }

  @HostListener('blur')
  public onBlur() {
    const value: string = this.ngControl.value || '';

    if (value) {
      this.setCtrlValue(this.formatPrice(value));
    }
  }

  public ngOnInit() {
    this.formatter = new Intl.NumberFormat('en-GB', {
      maximumFractionDigits: this.maxDecimalValueLength,
      minimumFractionDigits: this.maxDecimalValueLength,
    });
  }

  public ngAfterViewInit() {
    const value: string = this.ngControl.value || '';

    if (value) {
      this.setCtrlValue(this.formatPrice(value));
    }

    this.ngControl.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: (newValue: string) => {
        this.updateValue(newValue);
      },
    });
  }

  public updateValue(value: string) {
    const inputValue: string = value?.toString() || '';
    let validatedDecimalValue = '';

    if (inputValue) {
      validatedDecimalValue = this.validateDecimalValue(inputValue.replace(/[^0-9.]/g, ''));
    }

    const splittedValue: string[] = validatedDecimalValue.split('.');

    if (splittedValue.length === 2) {
      const decimal: string = splittedValue[1].slice(0, this.maxDecimalValueLength);
      const intger: string = splittedValue[0].slice(0, this.maxIntergerValueLength);
      validatedDecimalValue = `${intger}.${decimal}`;
    } else {
      validatedDecimalValue = splittedValue[0].slice(0, this.maxIntergerValueLength);
    }

    this.setCtrlValue(validatedDecimalValue);
  }

  public formatPrice(value: string): string {
    const valueToNumber: number = Number.parseFloat(value);

    return this.formatter.format(valueToNumber);
  }

  public unformatValue(value: string): string {
    if (!value) {
      return '';
    }

    return value.toString().replace(/,/g, '');
  }

  public ngOnDestroy() {
    if (this.ngControl.value) {
      this.setCtrlValue(this.unformatValue(this.ngControl.value));
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  private setCtrlValue(value: string) {
    this.ngControl.control.setValue(value, { emitEvent: false });
  }

  private validateDecimalValue(value: string): string {
    // Check to see if the value is a valid number or not
    const isValueNaN: boolean = Number.isNaN(Number(value));

    if (isValueNaN) {
      // this is an edge case, the user may add a `.` by mistake  ex : 1.1.
      // we remove the extra `.` in this part
      // strip out last char as this would have made the value invalid
      const strippedValue: string = value.slice(0, value.length - 1);

      // if value is still invalid, then this would be copy/paste scenario
      // and in such case we simply set the value to empty
      const isStrippedValueNaN: boolean = Number.isNaN(Number(strippedValue));

      return isStrippedValueNaN ? '' : strippedValue;
    }

    return value;
  }
}
