<div class="w-full" fxLayout="row" fxLayoutAlign="end center">
  <button
    fxLayout="row"
    fxLayoutAlign="center center"
    class="cursor-pointer navigate-back"
    [routerLink]="['../']"
  >
    <mat-icon class="arrow-icon">arrow_back</mat-icon>
    <p class="back-button">Atrás</p>
  </button>
</div>
