import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InMemoryStorageService {
  private storage = new Map<string, any>();

  public setKey(keyName: string, value: any): Observable<boolean> {
    this.storage.set(keyName, value);

    return of(true);
  }

  public getKey<Type>(keyName: string): Observable<Type> {
    const value: Type = (this.storage.get(keyName) as Type) || null;

    return of(value);
  }

  public removeKey(keyName: string): Observable<boolean> {
    return of(this.storage.delete(keyName));
  }
}
