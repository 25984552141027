import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HipUser, pwdChars8letters1numbers1 } from '@webtronic-labs/contracts-hip-hip';
import { from, of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { HipUserService } from '../../core/services/hip-user.service';
import { markControlsAsTouched } from '../../core/utils';
import { FirebaseService } from '../../firebase';
import { IFirebaseResetPasswordParams } from '../interfaces';
import firebase from 'firebase/app';
import { FirebaseRegisterErrorType } from '../../core/enums';

@Component({
  selector: 'hip-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent implements OnInit, OnDestroy {
  public changePasswordForm: FormGroup;
  public wasPasswordChanged = false;
  public hasOcurredError = false;
  public isLoggedIn = false;
  public currentUserEmail = '';
  public hasWrongResetPasswordCode = false;

  private resetPasswordCode: string;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private firebaseService: FirebaseService,
    private route: ActivatedRoute,
    private router: Router,
    private hipUserService: HipUserService
  ) {}

  public ngOnInit() {
    this.initForm();
    this.queryParamsSub();
  }

  public initForm(): void {
    this.changePasswordForm = new FormGroup({
      password: new FormControl(null, [
        Validators.required,
        Validators.pattern(pwdChars8letters1numbers1),
      ]),
      confirmPassword: new FormControl(null, [
        Validators.required,
        Validators.pattern(pwdChars8letters1numbers1),
      ]),
    });
  }

  public submitChangePassword(): void {
    const isFormInvalid: boolean =
      this.changePasswordForm.invalid || this.invalidPasswordConfirmation();

    if (isFormInvalid) {
      markControlsAsTouched(this.changePasswordForm);

      return;
    }

    if (this.isLoggedIn) {
      this.firebaseService
        .updatePassword(this.passwordControl.value)
        .pipe(
          switchMap(() => {
            const userId: string = this.firebaseService.getCurrentUser().uid;

            return this.hipUserService.updateUserFirstLogin(userId);
          }),
          take(1)
        )
        .subscribe({
          next: () => {
            this.wasPasswordChanged = true;
            this.hasOcurredError = false;
            this.router.navigate(['/']);
          },
          error: (err: firebase.FirebaseError) => {
            this.wasPasswordChanged = false;
            this.hasOcurredError = true;
            console.error(err);
          },
        });
    } else {
      this.firebaseService
        .changePassword(this.resetPasswordCode, this.passwordControl.value)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.wasPasswordChanged = true;
            this.hasOcurredError = false;
          },
          error: (err: firebase.FirebaseError) => {
            this.wasPasswordChanged = false;
            this.hasOcurredError = true;
            console.error(err);
          },
        });
    }

    this.changePasswordForm.reset();
  }

  public get passwordControl(): AbstractControl {
    return this.changePasswordForm.get('password');
  }

  public get confirmPasswordControl(): AbstractControl {
    return this.changePasswordForm.get('confirmPassword');
  }

  // Form validation
  public invalidPasswordConfirmation() {
    return (
      this.passwordControl.touched &&
      this.confirmPasswordControl.touched &&
      this.confirmPasswordControl.value !== this.passwordControl.value
    );
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private queryParamsSub(): void {
    this.route.queryParams
      .pipe(
        switchMap((params: IFirebaseResetPasswordParams) => {
          if (!!params.apiKey && !!params.oobCode) {
            this.resetPasswordCode = params.oobCode;

            return of(false);
          }

          return from(this.firebaseService.isLoggedIn());
        }),
        switchMap((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
          if (isLoggedIn) {
            return this.hipUserService
              .selectCurrentHipUser()
              .pipe(map((currentUser: HipUser) => currentUser.email));
          }

          return this.firebaseService.verifyPasswordResetCode(this.resetPasswordCode);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (email: string): void => {
          this.currentUserEmail = email;
          if (!this.isLoggedIn && !this.resetPasswordCode) {
            this.router.navigate(['/']);
          }
        },
        error: (err: firebase.FirebaseError | HttpErrorResponse) => {
          const code: string = (err as firebase.FirebaseError)?.code;

          if (code === FirebaseRegisterErrorType.InvalidActionCode) {
            this.hasWrongResetPasswordCode = true;
          }

          console.error(err);
        },
      });
  }
}
