import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { RouteDirectiveModule } from '../directives/route-directive-module';
import { HeaderComponent } from './layout/header/header.component';
import { TabSelectorComponent } from './layout/tab-selector/tab-selector.component';
import { CheckComponent } from './mixed-components/check/check.component';
import { InfoBlockComponent } from './mixed-components/info-block/info-block.component';
import { PasswordInputComponent } from './mixed-components/password-input/password-input.component';
import { ProgressBarComponent } from './mixed-components/progress-bar/progress-bar.component';
import { HipPopupsModule } from './popups/hip-popups.module';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { SearchBarWithFiltersComponent } from './mixed-components/search-bar-with-filters/search-bar-with-filters.component';
import { MatMenuModule } from '@angular/material/menu';
import { DocumentPoolComponent } from './mixed-components/document-pool/document-pool.component';
import { NotificationsModule } from '../notifications/notifications.module';
import { HelpInformationComponent } from './mixed-components/help-information/help-information.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ExpandedHeaderComponent } from './mixed-components/expanded-header/expanded-header.component';
import { FooterComponent } from './layout/footer/footer.component';

const components = [
  ProgressBarComponent,
  TabSelectorComponent,
  InfoBlockComponent,
  HeaderComponent,
  CheckComponent,
  TermsAndConditionsComponent,
  PasswordInputComponent,
  SearchBarWithFiltersComponent,
  DocumentPoolComponent,
  HelpInformationComponent,
  PrivacyPolicyComponent,
  ExpandedHeaderComponent,
  FooterComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    MatIconModule,
    FlexLayoutModule,
    MatIconModule,
    MatMenuModule,
    HipPopupsModule,
    RouteDirectiveModule,
    ReactiveFormsModule,
    RouterModule,
    NotificationsModule,
  ],
  exports: [...components],
})
export class HipComponentsModule {}
