<div
  class="full-container"
  fxLayout="column"
  fxLayoutAlign="center center"
  [routerLink]="routeToGoOnBackdropClick ? [routeToGoOnBackdropClick] : []"
  (click)="outsideClickEvent()"
>
  <div
    fxLayout="column"
    fxLayoutAlign="start start"
    class="inside-container"
    (click)="$event.stopPropagation()"
    [ngClass]="{
      'inside-container--rounded-radius': hasInsideRadius
    }"
  >
    <mat-icon *ngIf="canBeClosed" (click)="closeModal()" class="close-icon">close</mat-icon>

    <ng-content></ng-content>
  </div>
</div>
