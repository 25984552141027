<div class="form-field">
  <label class="font-md">{{ passwordInputLabel }}</label>
  <p class="font-italic font-xs" *ngIf="details">{{ details }}</p>
  <div [fxLayout]="isLargeInput ? 'column' : 'row'">
    <input
      name="password"
      autocomplete="current-password"
      [formControlName]="passwordControlName"
      placeholder="*********"
      class="form-field__input pr-12"
      [type]="shouldShowPassword ? 'text' : 'password'"
    />
    <div fxLayout="row" fxLayoutAlign="end end">
      <button type="button" (click)="togglePasswordVisibility()" class="password-visibility-button">
        <mat-icon class="align-middle">{{
          shouldShowPassword ? 'visibility_off' : 'visibility'
        }}</mat-icon>
      </button>
    </div>
  </div>
  <ng-content select="[errors]"></ng-content>
</div>
