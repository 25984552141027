import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppLoadingService } from 'projects/hip-lib/src/lib/core/services/app-loading.service';
import { FirebaseService, selectFirebaseUser } from 'projects/hip-lib/src/public-api';
import { Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import firebase from 'firebase/app';
import { FirebaseCollections, HipUser } from '@webtronic-labs/contracts-hip-hip';
import { setCurrentHipUser } from 'projects/hip-lib/src/lib/auth/store-auth/auth.actions';
import { setVersion } from 'projects/hip-lib/src/lib/store-app-root/app-root.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'hip-root',
  template: `
    <router-outlet></router-outlet>
    <hip-full-screen-loader *ngIf="isFullScreenLoading$ | async"></hip-full-screen-loader>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  public isFullScreenLoading$: Observable<boolean>;

  private destroy$ = new Subject<void>();

  constructor(
    private appLoadingService: AppLoadingService,
    private store: Store,
    private firebaseService: FirebaseService
  ) {}

  public ngOnInit() {
    this.isFullScreenLoading$ = this.appLoadingService.selectIsFullScreenLoading();
    this.currentUserSub();
    this.store.dispatch(setVersion({ version: environment.appVersion }));
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private currentUserSub() {
    this.store
      .pipe(
        select(selectFirebaseUser),
        switchMap((firebaseUser: firebase.User) => {
          if (!firebaseUser) {
            return of(null);
          }

          return this.firebaseService.getDoc(FirebaseCollections.Users, firebaseUser.uid);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (hipUser: HipUser) => {
          if (hipUser) {
            this.store.dispatch(setCurrentHipUser({ user: hipUser }));
          }
        },
      });
  }
}

// *** Should Support Safari, Edge, Chrome, Firefox ***
