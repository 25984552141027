import { createReducer, on } from '@ngrx/store';
import { IBankInformationStatus } from '../core';
import * as BanksDetailsActionTypes from './banks-details.actions';

export const banksDetailsFeatureKey = 'banksDetailsState';

export interface IBanksDetailsState {
  banksStatuses: IBankInformationStatus[];
  bankPaymentTypes: string[];
  isEditMode: boolean;
}

export const banksDetailsInitialState: IBanksDetailsState = {
  banksStatuses: [],
  bankPaymentTypes: [],
  isEditMode: false,
};

export const bankDetailsReducer = createReducer(
  banksDetailsInitialState,
  on(BanksDetailsActionTypes.setIsEditMode, (state: IBanksDetailsState, action) => {
    return { ...state, isEditMode: action.isEditMode };
  }),
  on(BanksDetailsActionTypes.setBankPaymentTypes, (state: IBanksDetailsState, action) => {
    return { ...state, bankPaymentTypes: action.bankPaymentTypes };
  }),
  on(BanksDetailsActionTypes.setBanksStatuses, (state: IBanksDetailsState, action) => {
    return { ...state, banksStatuses: action.banksStatuses };
  }),
  on(BanksDetailsActionTypes.toggleSelectBank, (state: IBanksDetailsState, action) => {
    const banksStatuses: IBankInformationStatus[] = [...state.banksStatuses];
    const bankIndex: number = banksStatuses.findIndex(
      (bankInformationStatus: IBankInformationStatus) =>
        bankInformationStatus.bankInformation.bank.name === action.bankName
    );

    if (bankIndex === -1) {
      return { ...state };
    }

    const updatedBankStatus: IBankInformationStatus = {
      ...banksStatuses[bankIndex],
      isSelected: !banksStatuses[bankIndex].isSelected,
    };

    banksStatuses[bankIndex] = updatedBankStatus;

    return { ...state, banksStatuses };
  })
);
