import { Component, Input } from '@angular/core';
import { LocalStorageKeys } from '../../core/enums';
import { Location } from '@angular/common';

@Component({
  selector: 'lib-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent {
  @Input()
  public mustSelectOption = false;
  public contactEmail = 'contacto@transferenciahipotecaria.com';

  constructor(private location: Location) {}

  public goBack(): void {
    this.location.back();
  }

  public acceptTerms(): void {
    localStorage.setItem(LocalStorageKeys.AcceptedPrivacyPolicy, 'true');
    this.location.back();
  }
}
