<h3 class="m-8">Términos y condiciones</h3>
<div class="terms-container" fxLayout="column" fxLayoutGap="1rem">
  <p>
    En este espacio se describen los términos y condiciones generales, en adelante
    <span class="font-bold">&#8220;TÉRMINOS Y CONDICIONES&#8221;</span> mediante los cuales se
    regirá el acceso y uso del sitio web disponible mediante la URL:
    <a href="www.transferenciahipotecaria.com" class="button-link"
      >www.transferenciahipotecaria.com</a
    >
    en adelante <span class="font-bold">&#8220;SITIO WEB&#8221;</span>, del cual es titular
    <span class="font-bold">&#8220;VIA SERVICIOS CON VALOR AGREGADO&#8221; S.A. DE C.V.</span>, en
    adelante &#8220;TITULAR&#8221;. Cualquier persona que desee acceder o hacer uso del sitio web,
    de los servicios que en él se ofrecen, así como la información contenida en dicho sitio web, ya
    sea que dicha información se encuentre disponible o no para su descarga, se sujetará
    incondicionalmente a los presentes TÉRMINOS Y CONDICIONES.
  </p>

  <h4>DEL OBJETO</h4>
  <p>
    El objeto de los presentes TÉRMINOS Y CONDICIONES es regular el acceso y la utilización del
    SITIO WEB, entendiendo por este cualquier tipo de contenido o servicio que se encuentre a
    disposición del público en general dentro del dominio:
    <a href="www.transferenciahipotecaria.com" class="button-link"
      >www.transferenciahipotecaria.com</a
    >
  </p>
  <p>
    El TITULAR se reserva la facultad de modificar en cualquier momento y sin previo aviso, la
    presentación, los contenidos, la funcionalidad, los servicios y la configuración que pudiera
    estar contenida en el SITIO WEB; en este sentido, el USUARIO reconoce y acepta que en cualquier
    momento podrá interrumpir, desactivar o cancelar cualquiera de los elementos que conforman en
    SITIO WEB o el acceso a los mismos.
  </p>

  <h4>CONDICIONES GENERALES DE USO</h4>
  <p>
    El acceso al SITIO WEB por parte del USUARIO tiene carácter libre y por regla general es
    gratuito, sin que el USUARIO tenga que proporcionar una contraprestación para poder disfrutar de
    ello, salvo en lo referente al costo del servicio por concepto de
    <span class="font-bold">gestoría electrónica especializada.</span>
  </p>
  <p>
    El acceso a parte de los contenidos y servicios del SITIO WEB podrá realizarse previa
    suscripción o registro previo del USUARIO.
  </p>
  <p>
    El SITIO WEB se encuentra dirigido exclusivamente a personas que se encuentran con la mayoría de
    edad (mayores de 18 años); y tengan contratado un CRÉDITO HIPOTECARIO vigente, con alguna
    institución de crédito, sociedades financieras de objeto múltiple reguladas y no reguladas,
    INFONAVIT, FOVISSSTE, Sociedad Hipotecaria Federal, entidades financieras que actúen como
    fiduciarias en fideicomisos que otorguen crédito, préstamo o financiamiento al público en
    general, en este sentido, el TITULAR declina cualquier responsabilidad por el incumplimiento de
    este requisito.
  </p>
  <p>
    Se hace del conocimiento del USUARIO que el TITULAR podrá administrar o gestionar el SITIO WEB
    de manera directa o a través de un tercero, lo cual no modifica en ningún sentido lo establecido
    en los presentes TÉRMINOS Y CONDICIONES. El solo acceso al SITIO WEB no supone el
    establecimiento de ningún tipo de relación entre el TITULAR y el USUARIO.
  </p>

  <h4>COMPROMISOS Y OBLIGACIONES DE LOS USUARIOS</h4>
  <p>
    El acceso o utilización del SITIO WEB, así como de los recursos habilitados para interactuar
    entre el USUARIO y el TITULAR tales como medios para realizar publicaciones o comentarios,
    confiere la condición de USUARIO del SITIO WEB, por lo que sin excepción quedará sujeto a todos
    y cada uno de los derechos y obligaciones derivados de los presentes TÉRMINOS Y CONDICIONES, así
    como a sus ulteriores modificaciones, sin perjuicio de la aplicación de la legislación
    aplicable, por tanto, se tendrán por aceptados desde el momento en el que se accede al SITIO
    WEB. Dada la relevancia de lo anterior, se recomienda al USUARIO revisar las actualizaciones que
    se realicen a los presentes TÉRMINOS Y CONDICIONES.
  </p>
  <p>
    Es responsabilidad del USUARIO utilizar el SITIO WEB de acuerdo con la forma en la que fue
    diseñado; en este sentido, queda prohibido modificar, ocultar, eludir o deshabilitar ningún
    elemento del SITIOWEB, la utilización de cualquier tipo de software que automatice la
    interacción o descarga de los contenidos o servicios proporcionados a través del SITIO WEB.
    Además, el USUARIO se compromete a utilizar la información, contenidos o servicios ofrecidos a
    través del SITIO WEB de manera lícita, sin contravenir lo dispuesto en los presentes TÉRMINOS Y
    CONDICIONES, la moral o el orden público, y se abstendrá de realizar cualquier acto que pueda
    suponer una afectación a los derechos de terceros, o perjudique de algún modo el funcionamiento
    del SITIO WEB.
  </p>
  <p>
    De igual forma, el USUARIO se compromete a proporcionar información lícita y veraz en los
    formularios habilitados en el SITIO WEB, en los cuales el USUARIO tenga que proporcionar datos o
    información para el acceso a algún contenido o servicio ofrecido por el propio SITIO WEB. En
    todo caso, el USUARIO notificará de forma inmediata al TITULAR acerca de cualquier hecho que
    permita suponer el uso indebido de la información registrada en dichos formularios, tales como
    robo, extravío, o acceso no autorizado a cuentas y/o contraseñas, con el fin de proceder a su
    inmediata cancelación.
  </p>
  <p>
    Así mismo el USUARIO manifiesta tener conocimiento y otorga su autorización para que el SITIO
    WEB a través de &#8220;VIA SERVICIOS CON VALOR AGREGADO&#8221; S.A. DE C.V. envíe a los Bancos
    autorizados y en operación (Instituciones de Banca Múltiple que forman el Sistema Bancario
    Mexicano), instituciones de crédito, sociedades financieras de objeto múltiple reguladas y no
    reguladas, entidades financieras que actúen como fiduciarias en fideicomisos que otorguen
    crédito, préstamo o financiamiento al público en general, la información necesaria para realizar
    la sustitución de acreedor y transferir su crédito hipotecario.
  </p>
  <p>
    El SITIO WEB no garantiza que el Banco acepte como su cliente al USUARIO, y autorice o acepte la
    SUSTITUCIÓN DE ACREEDOR o TRANSFERENCIA de su Crédito Hipotecario; ni tampoco será responsable
    la negativa del banco, por no ser verídica la información proporcionada por el USUARIO e
    ingresada al SITIO WEB.
  </p>
  <p>
    Al tratarse de un SITIO WEB dirigido exclusivamente a personas que cuenten con la mayoría de
    edad, el USUARIO manifiesta ser mayor de edad y disponer de la capacidad jurídica necesaria para
    sujetarse a los presentes TÉRMINOS Y CONDICIONES.
  </p>
  <p>
    En la utilización de la WEB, el USUARIO se compromete a no llevar a cabo ninguna conducta que
    pudiera dañar la imagen, los intereses y los derechos del TITULAR (o de terceros) o que pudiera
    dañar, inutilizar o sobrecargar el portal
    <a href="www.transferenciahipotecaria.com" class="button-link"
      >www.transferenciahipotecaria.com</a
    >
    o que impidiera de cualquier forma, la normal utilización de la web. No obstante, el USUARIO
    debe ser consciente de que las medidas de seguridad de los sistemas informáticos en Internet no
    son enteramente fiables y que, por tanto, no puede garantizar la inexistencia de virus u otros
    elementos que puedan producir alteraciones en los sistemas informáticos (software y hardware)
    del USUARIO o en sus documentos electrónicos y ficheros contenidos en los mismos.
  </p>

  <h4>DESCRIPCIÓN DEL SERVICIO Y SU FUNCIONAMIENTO</h4>
  <p>
    El principal objetivo del SITIO WEB es fomentar la <span class="font-bold">PORTABILIDAD</span> y
    <span class="font-bold">TRANSFERENCIA HIPOTECARIA</span>, vía SUSTITUCIÓN DEL ACREEDOR
    HIPOTECARIO; con información especializada en tiempo real y mediante aplicaciones de gestión y
    conexión electrónica, por lo que el SITIO WEB va dirigido específicamente a causahabientes de
    crédito hipotecario vigente, contratado con INFONAVIT, FOVISSSTE, Sociedad Hipotecaria Federal,
    Institución de Crédito, Sociedades Financieras de Objeto Múltiple reguladas y no reguladas, o
    Entidades Financieras que actúen como fiduciarias en fideicomisos que otorguen crédito o
    financiamiento hipotecario.
  </p>
  <p>
    Nuestro servicio de gestión y conexión electrónica ayuda al USUARIO, a través de la asignación
    de uno de nuestros ejecutivos denominado <span class="font-bold"></span>&#8220;NEEP&#8221;
    (Negociador Experto Electrónico Personalizado), en la búsqueda de la Institución Financiera
    (BANCO) que mayor descuento le ofrezca para la disminución de su tasa de interés y mejore
    condiciones de su crédito hipotecario.
  </p>
  <p>
    Al iniciar el proceso en el SITIO WEB, el USUARIO compartirá información de su perfil y
    características de su crédito hipotecario vigente, a través de formularios que le serán
    proporcionados en el SITIO WEB; con dicha información proporcionada y capturada por el USUARIO;
    se filtrara vía electrónica al USUARIO que, por su perfil y características generales, califica
    para acceder a las soluciones financieras que ofrece actualmente la Banca Mexicana, para
    transferir créditos hipotecarios.
  </p>
  <p>
    Posteriormente; al USUARIO se le asigna un <span class="font-bold"> NEEP</span>, quien lo
    atenderá de forma personal y dará seguimiento durante el proceso en el SITIO WEB. El
    <span class="font-bold"> NEEP</span>
    procederá a generar y enviar al USUARIO un máximo de dos &#8220;PROPUESTAS&#8221; (Cotizaciones
    o Tablas de Amortización), de las Instituciones Financiera (BANCOS) que de acuerdo al esquema en
    el que se encuentre el USURUARIO, las especificaciones y requisitos mínimos solicitados por cada
    Institución Financiera (BANCO), pueda cumplir.
  </p>
  <p>
    Después de que el USUARIO conoce las PROPUESTAS enviadas por el
    <span class="font-bold"> NEEP</span>, el USUARIO a través del SITIO WEB seleccionara de una de
    las dos PROPUESTAS recibidas. En seguida, se solicitará al USUARIO información adicional y
    documentación especifica en formato de PDF y/o JPG, con la finalidad de genera vía electrónica
    una Solicitud de Crédito y un expediente electrónico; por lo que el USUARIO es el único
    responsable de la veracidad de los datos, información y documentos que ingrese en el SITIO WEB,
    para obtener una PROPUESTA REAL y llegar a concretar su
    <span class="font-bold">TRANSFERENCIA HIPOTECARIA</span>.
  </p>
  <p>
    Continuando con el proceso el <span class="font-bold"> NEEP</span> procederá a la validación de
    la Solicitud de Crédito y los documentos que integran el expediente electrónico del USUARIO;
    para que sean enviados a la Institución Financiera (BANCO) seleccionada, y obtener su
    pre-autorización.
  </p>
  <p>
    Una vez que se reciba respuesta afirmativa de la Institución Financiera (BANCO) seleccionada, el
    <span class="font-bold"> NEEP</span> notificara al USUARIO dicha respuesta, y se encargara de
    agendar una <span class="font-bold">CITA PRESENCIAL</span> para el USUARIO, con la Institución
    Financiera (BANCO) seleccionada, para dar inicio formal al trámite de su
    <span class="font-bold">TRANSFERENCIA HIPOTECARIA</span>
  </p>
  <p>
    El <span class="font-bold"> NEEP</span> proporcionará los datos de la CITA agendada (fecha,
    hora, sucursal y nombre del ejecutivo bancario que lo atenderá), a la cual el USUARIO deberá
    acudir puntualmente, presentando la documentación original señalada y enlistada en su Solicitud
    de Crédito generada.
  </p>
  <p>
    Por último, el USUARIO confirmará en el SITIO WEB su conclusión del proceso de forma
    satisfactoria, en cuanto sea totalmente AUTORIZADA su
    <span class="font-bold">TRANSFERENCIA HIPOTECARIA</span>.
  </p>

  <h4>COSTOS Y FORMAS DE PAGOS</h4>
  <p>
    Todas las transacciones monetarias para la adquisición de servicios de gestión y conexión
    electrónica, son llevadas a cabo por tarjeta de crédito, tarjeta de débito a través de
    &#8220;STRIPE&#8221;, por tal motivo &#8220;STRIPE&#8221; es el único responsable del proceso de
    pago, el SITIO WEB no solicita ni almacena ningún dato relacionado con las tarjetas de crédito,
    códigos de seguridad, nombres de USUARIOS, ni contraseñas utilizadas por el servicio de
    &#8220;STRIPE&#8221; para el procesamiento de los pagos.
  </p>
  <p>
    Todos nuestros costos son en pesos mexicanos (IVA incluido); y se darán a conocer en cada etapa
    del proceso, en el SITIO WEB.
  </p>

  <h4>COSTOS Y FORMAS DE PAGOS</h4>
  <p>
    El USUARIO podrá realizar reclamaciones remitiendo un correo electrónico indicando su nombre y
    apellidos, el servicio adquirido y exponiendo los motivos de su reclamación.
  </p>
  <p>
    El USUARIO podrá notificarnos la reclamación, bien a través de correo electrónico a:
    contacto@transferenciahipotecaria.com, si lo desea adjuntando el siguiente formulario de
    reclamación:
  </p>
  <ul class="policies-list font-sm">
    <li>Servicio:</li>
    <li>Servicio:</li>
    <li>Nombre del USUARIO:</li>
    <li>Motivo de la reclamación:</li>
  </ul>

  <h4>LEGISLACIÓN Y JURISDICCIÓN APLICABLE</h4>
  <p>
    &#8220;VIA SERVICIOS CON VALOR AGREGADO&#8221; S.A. de C.V. se reserva la facultad de presentar
    las acciones civiles o penales que considere necesarias por la utilización indebida del SITIO
    WEB, sus contenidos o servicios, o por el incumplimiento de los presentes TÉRMINOS Y
    CONDICIONES.
  </p>
  <p>
    La relación entre el USUARIO y el SITIO WEB se regirá por la legislación vigente en la Ciudad de
    México. De surgir cualquier controversia en relación a la interpretación y/o a la aplicación
    incumplimiento de los presentes TÉRMINOS Y CONDICIONES, las partes se someterán expresamente al
    fuero y jurisdicción de los Jueces y Tribunales de la Ciudad de México, renunciando a cualquier
    otro que pudiere corresponder por razón de sus domicilios presentes o futuros.
  </p>

  <h4>CONTACTO</h4>
  <p>
    En caso de que cualquier USUARIO tuviese alguna duda acerca de estas Condiciones legales o
    cualquier comentario sobre el SITIO WEB, por favor contáctenos por medio del siguiente correo:
  </p>
  <a href="mailto:contacto@transferenciahipotecaria.com">contacto@transferenciahipotecaria.com</a>
  <p>
    De parte del equipo que formamos el SITIO WEB &#8220;TRANSFERENCIA HIPOTECARIA&#8221;, te
    agradecemos el tiempo dedicado en leer los presentes TÉRMINOS Y CONDICIONES.
  </p>
</div>
<div fxLayout="row" fxLayoutGap="2rem" class="p-8">
  <button type="button" (click)="goBack()" class="button button--secondary">Cerrar</button>
</div>
