<div class="progress-bar">
  <div
    [ngClass]="{
      'progress-bar-fill--gray': percentage < 10,
      'progress-bar-fill--yellow': percentage > 10 && percentage <= 30,
      'progress-bar-fill--green': percentage > 30 && percentage <= 75,
      'progress-bar-fill--lime': percentage > 75
    }"
    [ngStyle]="{ width: percentage + '%' }"
    class="progress-bar-fill"
  ></div>
</div>
