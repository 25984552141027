import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ModalComponent } from './modal/modal.component';

const components = [ModalComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, RouterModule, FlexLayoutModule, MatIconModule],
  exports: [...components],
})
export class HipPopupsModule {}
