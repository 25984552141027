/*
Add left padding to some if the length of the string is letter than the expected length.
This function is must used for add padding to numeric things like postal code that must have
5 characters and is a number (could be used for other thins too), by default we expect length
5 and pad with '0' string. Using the default parameter we have:
ex: '123'-> '00123';  '12345' -> '12345'
*/

export const addLeftPadding = (
  value: string,
  paddingValue: string = '0',
  length: number = 5
): string => {
  if (value === null || value === undefined || value.length >= length) {
    return value;
  }

  const paddingLength: number = length - value.length;
  const padding: string = paddingValue.repeat(paddingLength);

  const updatedValue: string = padding + value;

  return updatedValue;
};
