import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { emailValidator } from '@webtronic-labs/contracts-hip-hip';
import { take } from 'rxjs/operators';
import { FirebaseErrorMessages } from '../../core/enums';
import { FirebaseService } from '../../firebase';

@Component({
  selector: 'hip-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss'],
})
export class PasswordRecoveryComponent implements OnInit {
  public passwordRecoveryForm: FormGroup;
  public wasEmailSent = false;
  public hasOcurredError = false;
  public firebaseErrorMessages: typeof FirebaseErrorMessages = FirebaseErrorMessages;
  public isProcessing = false;

  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public ngOnInit() {
    this.initForm();
  }

  public submitRecoveryForm(): void {
    const email = this.passwordRecoveryForm.get('email').value;
    this.isProcessing = true;

    this.firebaseService
      .sendRecoveryEmail(email)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.wasEmailSent = true;
          this.hasOcurredError = false;
          this.isProcessing = false;
        },
        error: () => {
          this.hasOcurredError = true;
          this.wasEmailSent = false;
          this.isProcessing = false;
        },
      });
    this.passwordRecoveryForm.reset();
  }

  public get emailControl(): AbstractControl {
    return this.passwordRecoveryForm.get('email');
  }

  public navigateToAuth() {
    this.router.navigate(['/auth']);
  }

  private initForm(): void {
    this.passwordRecoveryForm = new FormGroup({
      email: new FormControl(this.route.snapshot.queryParams?.email, [
        Validators.required,
        Validators.pattern(emailValidator),
      ]),
    });
  }
}
