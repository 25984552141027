// this function check the given fields (fieldsToCheck) of an object and remove this fields is they are empty(null, undefine)
export const removeNullFields = (
  objectToClean: { [key: string]: any },
  fieldsToCheck: string[]
): { [key: string]: any } => {
  const cleanObject = { ...objectToClean };
  for (const key of fieldsToCheck) {
    if (key in cleanObject && (cleanObject[key] === null || cleanObject[key] === undefined)) {
      delete cleanObject[key];
    }
  }

  return cleanObject;
};
