<h3 class="m-8">Política de Privacidad</h3>
<div class="terms-container" fxLayout="column" fxLayoutGap="1rem">
  <p>
    Mediante el presente <span class="font-bold">AVISO DE PRIVACIDAD</span>, las entidades
    integrantes del <span class="font-bold">TRANSFERENCIA HIPOTECARIA</span>, informan a Usted los
    términos en que serán tratados los Datos Personales que recaben, a través del sitio
    www.transferenciahipotecaria.com en adelante <span class="font-bold">"SITIO WEB&#8221;</span>,
    del cual es titular
    <span class="font-bold"
      >&#8220;VIA SERVICIOS CON VALOR AGREGADO&#8221; S.A. DE C.V., en adelante
      &#8220;RESPONSABLE&#8221;</span
    >. Cualquier persona que desee acceder o hacer uso del sitio web o los servicios que en él se
    ofrecen, podrá hacerlo sujetándose a las políticas y principios incorporadas al presente
    documento, por lo cual, le recomendamos que lea atentamente la siguiente información:
  </p>
  <p>
    <span class="font-bold">DEFINICIONES.</span> Para efectos del presente
    <span class="font-bold">AVISO DE PRIVACIDAD</span>, los términos que se indican a continuación,
    tendrán los significados que en este documento se señalan, ya sea que estén redactados en
    singular o en plural:
  </p>
  <p>
    <span class="font-bold">&#8220;Datos Personales&#8221;:</span> Cualquier información
    concerniente a una persona física identificada o identificable.
  </p>
  <p>
    <span class="font-bold">&#8220;Datos Personales Sensibles&#8221;:</span> Aquellos Datos
    Personales que afecten a la esfera íntima del Titular, o cuya utilización indebida pueda dar
    origen a cualquier tipo de discriminación o conlleve un riesgo grave para el Titular. Están
    considerados dentro de esta categoría aquellos datos que puedan revelar aspectos personales del
    Titular, como origen racial, étnico, preferencia sexual o situación financiera.
  </p>
  <p>
    <span class="font-bold">&#8220;Encargado&#8221;:</span> La persona física o moral que sola o
    conjuntamente con otras personas físicas o morales trate Datos Personales a nombre y mediante
    instrucción del Responsable, conforme a los procesos, términos y condiciones que este le señale.
  </p>
  <p>
    <span class="font-bold">&#8220;Entidad Comercial&#8221;:</span> a las sociedades que de manera
    habitual otorguen créditos, préstamos o financiamientos al público.
  </p>
  <p>
    <span class="font-bold">&#8220;Sustitución de Acreedor&#8221;:</span> como su nombre lo dice, es
    sustituir o subrogar a su acreedor hipotecario, derecho que el Titular podrá ejercer para
    refinanciar o, más precisamente, renegociar las condiciones financieras de la deuda en su
    crédito hipotecario, con entidades dispuestas a subrogarse en las obligaciones hipotecarias
    derivadas de su contrato de financiación vigente, mediante la conclusión de nuevas operaciones
    de financiamiento. Se permite así al Titular aprovecharse de la mejora de las condiciones
    originarias por efecto de la competencia entre Entidades Financiera o Comerciales.
  </p>
  <p>
    <span class="font-bold">&#8220;LFPDPPP&#8221;:</span> Ley Federal de Protección de Datos
    Personales en Posesión de los Particulares.
  </p>
  <p>
    <span class="font-bold">&#8220;Reglamento&#8221;:</span> Reglamento de la Ley Federal de
    Protección de Datos Personales en Posesión de los Particulares.
  </p>
  <p>
    <span class="font-bold">"Responsable&#8221;:</span> Es cada entidad perteneciente a
    Transferencia Hipotecaria, que actuando en lo individual o en conjunto con otras entidades
    recabe Datos Personales para su tratamiento conforme a las finalidades señaladas en este AVISO
    DE PRIVACIDAD. A estos efectos, podrá ser Responsable:
  </p>

  <ul class="policies-list font-sm">
    <li>&#8220;Vía Servicios Con Valor Agregado&#8221; S.A. de C.V.</li>
    <li>&#8220;Negociadores Electrónicos Expertos Personalizados&#8221;, S.A. de C.V.</li>
    <li>&#8220;Sociedad de Transferencia Hipotecaria&#8221;, S.A. de C.V.</li>
  </ul>

  <p>
    <span class="font-bold">&#8220;Sitio Web&#8221;:</span> Es
    <a href="www.transferenciahipotecaria.com" class="button-link"
      >www.transferenciahipotecaria.com</a
    >
  </p>

  <p>
    <span class="font-bold">&#8220;Titular&#8221;:</span> La persona física a quien corresponden los
    Datos Personales.
  </p>

  <p>
    <span class="font-bold">&#8220;Transferencia&#8221;:</span> La comunicación de Datos Personales
    dentro o fuera del territorio nacional, realizada a persona distinta del Titular o del
    Responsable.
  </p>

  <p>
    <span class="font-bold">&#8220;Usuario&#8221;:</span> A cualquier persona física que
    directamente, realice operaciones con la Entidad de que se trate, o utilice los servicios que le
    ofrezca el Responsable, sin tener una relación comercial permanente con esta.
  </p>

  <h4>POLÍTICA DE PRIVACIDAD</h4>
  <p>
    De conformidad con lo establecido en la LFPDPPP y su Reglamento, el Responsable se compromete a
    adoptar las medidas necesarias que estén a su alcance para asegurar la privacidad de los datos
    personales recabados de forma que se garantice su seguridad, se evite su alteración, pérdida o
    tratamiento no autorizado.
  </p>
  <p>
    Además, a efecto de dar cumplimiento a lo establecido en la LFPDPPP y su Reglamento, todo dato
    personal que sea recabado a través del SITIO WEB, será tratado de conformidad con los principios
    de licitud, calidad, finalidad, lealtad y responsabilidad. Todo tratamiento de datos personales
    quedará sujeto al consentimiento de su Titular. En todo caso, la utilización de datos
    financieros o patrimoniales, requerirán de autorización expresa de sus titulares, no obstante,
    esta podrá darse a través del propio SITIO WEB, utilizando los Medios Electrónicos que el
    Responsable ponga a su disposición, y en todo caso se dará la mayor diligencia y cuidado a este
    tipo de datos con el propósito de dar seguimiento a una solicitud para Transferir su Crédito
    Hipotecario a otra Entidad Financiera o Comercial. Lo mismo ocurrirá en el caso de los datos
    personales sensibles, considerando por estos, aquellos que debido a una utilización indebida
    puedan dar origen a discriminación o su divulgación conlleve un riesgo para el Titular.
  </p>
  <p>
    El SITIO WEB podrá incluir hipervínculos o enlaces que permitan acceder a páginas web de
    terceros distintos del Responsable. Los titulares de dichos sitios web dispondrán de sus propias
    políticas de privacidad y protección de datos, por lo cual el Responsable no asume ningún tipo
    de responsabilidad por los datos que sean facilitados por el USUARIO a través de cualquier sitio
    web distinto a
    <a href="www.transferenciahipotecaria.com" class="button-link"
      >www.transferenciahipotecaria.com</a
    >
  </p>
  <p>
    Por otra parte, el acceso al SITIO WEB NO utilizara cookies, las cuales son pequeñas cantidades
    de información que se almacenan en el navegador utilizado por el USUARIO. Las cookies facilitan
    la navegación, la hacen más amigable, y no dañan el dispositivo de navegación, por ello no se
    almacenará las preferencias del USUARIO, así como la interacción que este tenga con el SITIO
    WEB.
  </p>
  <p>
    El tratamiento de datos personales se limitará al cumplimiento de las finalidades previstas en
    el <span class="font-bold">Aviso de Privacidad</span> el cual se describe a continuación:
  </p>

  <h4>AVISO DE PRIVACIDAD</h4>
  <p>
    Cuando te pedimos llenar los campos de información personal con la cual puedes ser identificado,
    lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin
    embargo este Aviso de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le
    recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo
    con dichos cambios.
  </p>
  <h5>Información que es recogida</h5>
  <p>
    Nuestro SITIO WEB recogerá información personal, por lo que el Responsable le informa que
    recabará las siguientes categorías de Datos Personales: Datos de Identificación; Datos de
    Contacto como su dirección de correo electrónica, número telefónico. Así mismo cuando sea
    necesario podrá ser requerida información específica como Datos patrimoniales y/o financieros;
    Datos laborales; Datos académicos o profesionales; para procesar nuestro servicio de gestión y
    conexión electrónica, para la TRANSFERENCIA de su crédito Hipotecario.
  </p>
  <p>
    El Responsable podrá, al amparo del presente AVISO DE PRIVACIDAD, verificar, adicionar o
    cumplimentar los Datos Personales recabados directamente del Titular, así como aquellos Datos
    Personales de terceros que sean proporcionados por el Titular, tales como información sobre sus
    referencias, obligados solidarios o cotitulares según resulte aplicable conforme al servicio.
    Será responsabilidad del Titular informar a las personas antes señaladas respecto de la
    información que proporcionó al Responsable y los fines de su entrega, así como el contenido del
    presente AVISO DE PRIVACIDAD. El Titular manifiesta que los Datos Personales proporcionados al
    Responsable son veraces y actualizados y se obliga a comunicar al Responsable cualquier
    modificación a los Datos Personales proporcionados con la mayor brevedad posible a través de los
    Medios Electrónicos que el Responsable ponga a su disposición.
  </p>
  <h5>Uso de la información recogida</h5>
  <p>
    Los Datos Personales que el Responsable recabe serán utilizados para atender las siguientes
    finalidades:
  </p>
  <ul class="policies-list font-sm">
    <li>
      Corroborar la identidad del Titular y la veracidad de la información y documentos que
      proporcione como propios, así como la de sus referencias, obligados solidarios, o cotitulares
      según resulte aplicable conforme al producto hipotecario contratado.
    </li>
    <li>
      Realizar el análisis de la información proporcionada por el Titular, su viabilidad de pago,
      perfil transaccional y en general, cualquier otra evaluación que sea necesaria o resulte
      conveniente, a fin de determinar conforme al perfil del Titular, de las características de su
      crédito hipotecario, y de acuerdo a las ofertas bancarias de crédito hipotecario vigentes,
      cual Entidad Financiera o Comercial, el Titular es susceptible de ser aceptado para transferir
      su crédito hipotecario; obteniendo una menor tasa de interés y los mejores beneficios para el
      crédito hipotecario del Titular, cumpliendo con los requisitos mínimos que para dicha
      operación y/o SUSTITUCIÓN DE ACREEDOR soliciten las Entidades Financieras o Comerciales.
    </li>
    <li>
      Integrar expedientes y bases de datos, y dar tratamiento a los mismos, ya sea directamente por
      el Responsable o a través de un tercero, con el fin de que el Responsable pueda prestar los
      servicios de gestión electrónica propios de su objeto y en su caso reportar a las autoridades
      administrativas y jurisdiccionales dicha información cuando así lo establezcan las
      disposiciones legales vigentes.
    </li>
    <li>
      Conectar al Titular con una Entidad Financiera o Entidad Comercial, para que la Entidad
      Financiera o Entidad Comercial le autorice al Titular, la transferencia de su crédito
      hipotecario mediante una SUSTITUCIÓN DE ACREEDOR, formalizando así una relación contractual
      entre ellos.
    </li>
    <li>
      Adicional, nuestro SITIO WEB emplea la información con el fin de proporcionar el mejor
      servicio posible, particularmente para mantener un registro de usuarios, y mejorar nuestros
      servicios. Es posible que sean enviados correos electrónicos periódicamente a través de
      nuestro SITIO WEB con ofertas especiales, nuevos productos y otra información publicitaria que
      consideremos relevante para el USUARIO, o que pueda brindarle algún beneficio; estos correos
      electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en
      cualquier momento.
    </li>
  </ul>

  <h5>Control de su información personal</h5>
  <p>
    EL Responsable NO venderá, cederá ni distribuirá la información personal que es recopilada sin
    su consentimiento, salvo que sea requerido por un juez con un orden judicial.
  </p>
  <p>
    El Responsable se reserva el derecho de cambiar los términos de la presente Política de
    Privacidad en cualquier momento.
  </p>

  <h4>MEDIDAS DE SEGURIDAD</h4>
  <p>
    Los datos personales comunicados por el USUARIO pueden ser almacenados en bases de datos
    automatizadas o no, cuya titularidad corresponde en exclusiva a el Responsable, asumiendo ésta
    todas las medidas de índole técnica, organizativa y de seguridad que garantizan la
    confidencialidad, integridad y calidad de la información contenida en las mismas de acuerdo con
    lo establecido en la normativa vigente en protección de datos.
  </p>
  <p>
    La comunicación entre los USUARIOS y el SITIO WEB utiliza un canal seguro, y los datos
    transmitidos son cifrados gracias a protocolos https, por tanto, garantizamos las mejores
    condiciones de seguridad para que la confidencialidad de los USUARIOS esté garantizada.
  </p>

  <h4>RECLAMACIONES</h4>
  <p>
    El USUARIO podrá realizar reclamaciones remitiendo un correo electrónico indicando su nombre y
    apellidos, el servicio adquirido y exponiendo los motivos de su reclamación.
  </p>
  <p>
    El USUARIO podrá notificarnos la reclamación, bien a través de correo electrónico a:
    <a>contacto@transferenciahipotecaria.com</a>, si lo desea adjuntando el siguiente formulario de
    reclamación:
  </p>

  <ul class="policies-list font-sm">
    <li>Servicio:</li>
    <li>Adquirido el día:</li>
    <li>Nombre del USUARIO</li>
    <li>Motivo de la reclamación:</li>
  </ul>

  <h4>DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h4>
  <p>
    &#8220;Sociedad de Transferencia Hipotecaria&#8221; S.A. de C.V. por sí o como parte cesionaria,
    es titular de todos los derechos de propiedad intelectual e industrial del SITIO WEB,
    entendiendo por este el código fuente que hace posible su funcionamiento, así como las imágenes,
    archivos de audio y video, logotipos, marcas, combinaciones de colores, estructuras, diseños,
    formularios, y demás elementos que lo distinguen. Serán, por consiguiente, protegidas por la
    legislación mexicana en materia de propiedad intelectual e industrial, así como por los tratados
    internacionales aplicables. Por consiguiente, queda expresamente prohibida la reproducción,
    distribución o difusión de los contenidos del SITIO WEB, con fines comerciales en cualquier
    soporte y por cualquier medio, sin la autorización de &#8220;VIA SERVICIOS CON VALOR
    AGREGADO&#8221; S.A. de C.V.
  </p>
  <p>
    El USUARIO se compromete a respetar los derechos de propiedad intelectual e industrial del
    Responsable. No obstante, además de poder visualizar los elementos del SITIO WEB podrá
    imprimirlos o almacenarlos, siempre y cuando sea exclusivamente para su uso estrictamente
    personal.
  </p>
  <p>
    Por otro lado, el USUARIO, se abstendrá de suprimir, alterar o manipular cualquier elemento,
    archivo o contenido del SITIO WEB, y por ningún motivo realizará actos tendientes a vulnerar la
    seguridad, los archivos o bases de datos que se encuentren protegidos, ya sea a través de un
    acceso restringido mediante un usuario y contraseña, o porque no cuente con los permisos para
    visualizarlos, editarlos o manipularlos.
  </p>
  <p>
    EEn caso de que el USUARIO o algún tercero consideren que cualquiera de los contenidos del SITIO
    WEB suponga una violación de los derechos de protección de la propiedad industrial o
    intelectual, deberá comunicarlo inmediatamente al Responsable a través de los datos de contacto
    disponibles en el propio SITIO WEB.
  </p>

  <h4>POLÍTICA DE COMENTARIOS</h4>
  <p>
    En nuestro SITIO WEB se permiten realizar comentarios para enriquecer los contenidos y realizar
    consultas. No se admitirán comentarios que no estén relacionados con la temática de esta WEB,
    que incluyan difamaciones, agravios, insultos, ataques personales o faltas de respeto en general
    hacia el autor o hacia otros miembros. También serán suprimidos los comentarios que contengan
    información que sea obviamente engañosa o falsa, así como los comentarios que contengan
    información personal, como, por ejemplo, domicilios privado o teléfonos y que vulneren nuestra
    política de protección de datos.
  </p>
  <P>
    Se desestimará, igualmente, aquellos comentarios creados sólo con fines promocionales de una
    web, persona o colectivo y todo lo que pueda ser considerado spam en general.
  </P>
  <p>
    No se permiten comentarios anónimos, así como aquellos realizados por una misma persona con
    distintos apodos. No se considerarán tampoco aquellos comentarios que intenten forzar un debate
    o una toma de postura por otro usuario.
  </p>

  <h4>EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</h4>
  <p>
    El Responsable no otorga ninguna garantía ni se hace responsable, en ningún caso, de los daños y
    perjuicios de cualquier naturaleza que pudieran traer causa de:
  </p>
  <ul class="policies-list font-sm">
    <li>
      La falta de disponibilidad, mantenimiento y efectivo funcionamiento de la web, o de sus
      servicios y contenidos;
    </li>
    <li>La existencia de virus, programas maliciosos o lesivos en los contenidos;</li>
    <li>El uso ilícito, negligente, fraudulento o contrario a este Aviso Legal;</li>
    <li>
      La falta de licitud, calidad, fiabilidad, utilidad y disponibilidad de los servicios prestados
      por terceros y puestos a disposición de los usuarios en el SITIO WEB.
    </li>
    <li>
      El prestador no se hace responsable bajo ningún concepto de los daños que pudieran dimanar del
      uso ilegal o indebido de la presente página web.
    </li>
  </ul>

  <h4>CAMBIOS AL AVISO DE PRIVACIDAD</h4>
  <p>
    El Responsable se reserva el derecho a realizar cambios en el presente AVISO DE PRIVACIDAD, los
    cuales serán dados a conocer mediante su publicación en la página
    <a href="www.transferenciahipotecaria.com">www.transferenciahipotecaria.com</a> o aquella que la
    llegara a sustituir.
  </p>
  <p>
    Asimismo, se informa al Titular que cuenta con el derecho a oponerse al tratamiento de sus Datos
    Personales en caso de no estar de acuerdo con las modificaciones que pudiera presentar este
    AVISO DE PRIVACIDAD, para ello, deberá enviar una solicitud a la dirección de correo electrónico
    <a>contacto@transferenciahipotecaria.com</a>
  </p>
  <p>
    Les informamos que la autoridad competente para resolver cualquier conflicto derivado de la
    aplicación a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y
    su Reglamento, es el Instituto Nacional de Acceso a la Información y Datos Personales (INAI) y
    puede saber más del tema en su página www.inai.org.mx
  </p>

  <h4>LEGISLACIÓN Y JURISDICCIÓN APLICABLE</h4>
  <p>
    &#8220;VIA SERVICIOS CON VALOR AGREGADO&#8221; S.A. de C.V. se reserva la facultad de presentar
    las acciones civiles o penales que considere necesarias por la utilización indebida del SITIO
    WEB, sus contenidos o servicios, o por el incumplimiento del presente AVISO DE PRIVACIDAD.
  </p>
  <p>
    La relación entre el USUARIO y el RESPONSABLE se regirá por la legislación vigente en la Ciudad
    de México. De surgir cualquier controversia en relación a la interpretación y/o a la aplicación
    incumplimiento del presente AVISO DE PRIVACIDAD, las partes se someterán expresamente al fuero y
    jurisdicción de los Jueces y Tribunales de la Ciudad de México, renunciando a cualquier otro que
    pudiere corresponder por razón de sus domicilios presentes o futuros.
  </p>

  <h4>CONTACTO</h4>
  <p>
    En caso de que cualquier USUARIO tuviese alguna duda acerca de estas Condiciones legales o
    cualquier comentario sobre el SITIO WEB, por favor contáctenos por medio del siguiente correo:
  </p>
  <p>
    <a [href]="'mailto:' + contactEmail" class="button-link">{{ contactEmail }}</a>
  </p>
</div>
<div fxLayout="row" fxLayoutGap="2rem" class="p-8">
  <button type="button" (click)="goBack()" class="button button--secondary">Cerrar</button>
</div>
