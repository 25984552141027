import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, UrlTree } from '@angular/router';
import { HipUser, RoleType } from '@webtronic-labs/contracts-hip-hip';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HipUserService } from '../services/hip-user.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private hipUserService: HipUserService) {}

  public canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canEnterTheRoute();
  }

  public canActivateChild():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canEnterTheRoute();
  }

  public canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.canEnterTheRoute();
  }

  private canEnterTheRoute(): Observable<boolean> | Promise<boolean> | boolean {
    return this.hipUserService.selectCurrentHipUser().pipe(
      map((hipUser: HipUser) => {
        return hipUser?.role === RoleType.Admin;
      })
    );
  }
}
