import { AbstractControl, FormGroup } from '@angular/forms';

export const getFirstInvalidField = (formGroup: FormGroup): string => {
  for (const field of Object.keys(formGroup.controls)) {
    const control: AbstractControl = formGroup.get(field);
    if (control.invalid) {
      return field;
    }
  }

  return null;
};
