<hip-modal [hasInsideRadius]="true" *ngIf="!wasPasswordChanged && !hasWrongResetPasswordCode">
  <div class="instructions">
    <h3 class="u-font-black">Cambiar Contraseña</h3>
    <span class="color-headings">{{ currentUserEmail }}</span>
    <ul>
      <li>
        <p class="list-item">&nbsp; 8 caracteres o más</p>
      </li>
      <li>
        <p class="list-item">&nbsp; Al menos 1 mayúscula, 1 minúscula y un número</p>
      </li>
    </ul>
    <p *ngIf="hasOcurredError" class="error-message font-md">
      Hubo un problema. Inténtelo de nuevo.
    </p>
  </div>

  <div class="password-form">
    <form [formGroup]="changePasswordForm" (ngSubmit)="submitChangePassword()">
      <lib-password-input [isLargeInput]="true" passwordInputLabel="Nueva Contraseña"
        ><div errors>
          <p
            *ngIf="passwordControl.touched && passwordControl.errors?.required"
            class="form-field__error font-md"
          >
            Contraseña requerida
          </p>

          <p
            *ngIf="passwordControl.touched && passwordControl.errors?.pattern"
            class="form-field__error font-md"
          >
            Al menos 8 caractéres, 1 mayúscula, 1 minúscula y un número
          </p>
        </div>
      </lib-password-input>

      <lib-password-input
        [isLargeInput]="true"
        passwordControlName="confirmPassword"
        passwordInputLabel="Repetir Contraseña"
      >
        <div errors>
          <p *ngIf="invalidPasswordConfirmation()" class="form-field__error font-md">
            Las contraseñas no coinciden
          </p>
        </div>
      </lib-password-input>

      <p *ngIf="wasPasswordChanged" class="form-field__success font-md">
        Contraseña cambiada con éxito
      </p>

      <div fxLayout="row" fxLayoutGap="1rem">
        <button type="submit" class="button">Restablecer</button>
        <button type="button" class="button button--secondary" [routerLink]="['/auth']">
          Cerrar
        </button>
      </div>
    </form>
  </div>
</hip-modal>

<hip-modal type="fixed" *ngIf="wasPasswordChanged">
  <div
    fxLayout="column"
    class="confirmation-modal"
    fxLayoutGap="3rem"
    fxLayoutAlign="center center"
  >
    <span class="px-8 mt-8">Su Contraseña ha sido cambiada con éxito.</span>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2rem" class="mb-8">
      <button class="button" [routerLink]="[isLoggedIn ? '/' : '/auth']">
        <span class="px-4">Aceptar</span>
      </button>
    </div>
  </div>
</hip-modal>

<hip-modal type="fixed" *ngIf="hasWrongResetPasswordCode">
  <div
    fxLayout="column"
    class="confirmation-modal"
    fxLayoutGap="3rem"
    fxLayoutAlign="center center"
  >
    <span class="px-8 mt-8">Código de cambio de cambio de contraseña incorrecto.</span>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2rem" class="mb-8">
      <button class="button" [routerLink]="[isLoggedIn ? '/' : '/auth']">
        <span class="px-4">Aceptar</span>
      </button>
    </div>
  </div>
</hip-modal>
