import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  FirebaseCollections,
  IGenericApiResponse,
  INotificationsInDatabaseStorage,
  IUserNotification,
} from '@webtronic-labs/contracts-hip-hip';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FirebaseService } from '../firebase';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private store: Store,
    private firebaseService: FirebaseService,
    private http: HttpClient
  ) {}

  public fetchNotifications() {
    return this.http.get<INotificationsInDatabaseStorage>(`${environment.apiUrl}/notifications`);
  }

  public createNotification(notification: IUserNotification) {
    return this.http.post<IGenericApiResponse>(`${environment.apiUrl}/notifications`, {
      notification,
    });
  }

  public deleteNotification(id: string) {
    return this.http.delete<IGenericApiResponse>(`${environment.apiUrl}/notifications/${id}`);
  }

  public deleteNotifications(ids: string[]) {
    const options: {
      headers: HttpHeaders;
      body: {
        [key: string]: string | string[];
      };
    } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        ids,
      },
    };

    return this.http.delete<IGenericApiResponse>(`${environment.apiUrl}/notifications`, options);
  }

  public acknowledgeNotification(id: string) {
    return this.http.patch<IGenericApiResponse>(
      `${environment.apiUrl}/notifications/acknowledge/${id}`,
      {}
    );
  }

  public acknowledgeNotifications(ids: string[]) {
    return this.http.put<IGenericApiResponse>(`${environment.apiUrl}/notifications/acknowledge`, {
      ids,
    });
  }

  public getCurrentUserNotifications(): Observable<IUserNotification[]> {
    return this.firebaseService
      .subscribeToDocument(
        FirebaseCollections.Notifications,
        this.firebaseService.getCurrentUser().uid
      )
      .pipe(
        map((result: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => {
          if (!result) {
            return [];
          }

          const notifications: IUserNotification[] = result.data()?.notifications || [];

          const sortedNotifications: IUserNotification[] =
            this.sortNotificationsByDate(notifications);

          return sortedNotifications;
        })
      );
  }

  private sortNotificationsByDate(notifications: IUserNotification[]): IUserNotification[] {
    const sortedNotifications: IUserNotification[] = notifications.sort(
      (a: IUserNotification, b: IUserNotification) => {
        if (a.createdAt > b.createdAt) {
          return -1;
        }

        return 1;
      }
    );

    return sortedNotifications;
  }
}
