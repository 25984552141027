import { Component, OnInit } from '@angular/core';
import { AppLoadingService } from '../../core/services/app-loading.service';

@Component({
  selector: 'lib-terms-and-conditions-public',
  templateUrl: './terms-and-conditions-public.component.html',
  styleUrls: ['./terms-and-conditions-public.component.css'],
})
export class TermsAndConditionsPublicComponent implements OnInit {
  constructor(private appLoadingService: AppLoadingService) {}

  public ngOnInit(): void {
    this.appLoadingService.setFullScreenLoadingAsync(false);
  }
}
