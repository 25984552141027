// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from 'projects/hip-lib/src/public-api';

export const environment: IEnvironment = {
  isDemo: true,
  appVersion: '1.0.0-dev',
  production: false,
  apiUrl: 'https://api-dev-transferencia-hipotecaria-nyuxazcw5a-uc.a.run.app',
  // apiUrl: 'http://localhost:3000',
  firebaseConfig: {
    apiKey: 'AIzaSyBJEBJIDvqbohhSCa_rq5s2vxZv5lvg57U',
    authDomain: 'dev-hip-hip.firebaseapp.com',
    projectId: 'dev-hip-hip',
    storageBucket: 'dev-hip-hip.appspot.com',
    messagingSenderId: '787223874382',
    appId: '1:787223874382:web:4742fe1cdb8337906d6c1f',
    measurementId: 'G-VSKJT7J049',
  },
  googleStaticMapsApiKey: 'AIzaSyDyGfcCPfsSlv0QkHu4FVDcmuhUKKFSSsE',
  // Sentry
  sentryConfiguration: {
    environment: 'demo',
    dsn: '',
    tracingOrigins: [
      'localhost',
      'dev.transferenciahipotecaria.com',
      'dev-neep.transferenciahipotecaria.com',
    ],
    tracesSampleRate: 1.0,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
