import { Component, Input } from '@angular/core';

@Component({
  selector: 'hip-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
})
export class CheckComponent {
  @Input()
  public isChecked = false;
}
