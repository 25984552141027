// give size in bytes return the size in the most possible higher unit
// ex: 30720 -> 30.00Kb, 31457280 -> 3.00Mb

const units: string[] = ['bytes', 'KB', 'MB', 'GB'];

export const roundFileSize = (size: number): string => {
  let sizeInUnit: number = size;
  let unit: string = units[0];

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let index = 0; index < units.length; index++) {
    const currentScale: number = Math.pow(1024, index);
    const scaledSize: number = size / currentScale;

    if (scaledSize < 1) {
      break;
    }

    sizeInUnit = scaledSize;
    unit = units[index];
  }

  return `${sizeInUnit.toFixed(2)}${unit}`;
};
