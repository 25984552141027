import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.scss'],
})
export class InfoBlockComponent {
  @Input()
  public title = '';
  @Input()
  public canDismiss = false;

  @Output()
  public dismissMessage = new EventEmitter<void>();
}
