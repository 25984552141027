import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

/**
 * Use as:
 *  <div [webReachedEndScroll]="eventCatcher()">
      // Content
    </div>
 */
@Directive({
  selector: '[webReachedEndScroll]',
})
export class ReachedEndDirective implements OnInit {
  @Input()
  public shouldTriggerOnInit = false;

  @Output()
  public reachedEndOfTable = new EventEmitter<void>();

  constructor(private element: ElementRef) {}

  public ngOnInit() {
    if (this.shouldTriggerOnInit) {
      const isAtEndOfTable = this.verifyIsAtEndOfTable(this.element.nativeElement);

      if (isAtEndOfTable) {
        this.reachedEndOfTable.emit();
      }
    }
  }

  @HostListener('scroll', ['$event'])
  public onScroll(event: MouseEvent) {
    if (this.verifyIsAtEndOfTable(event.target as HTMLDivElement)) {
      this.reachedEndOfTable.emit();
    }
  }

  private verifyIsAtEndOfTable(target: HTMLDivElement): boolean {
    const tableViewHeight: number = (<HTMLTableElement>target).offsetHeight;
    const tableScrollHeight: number = (<HTMLTableElement>target).scrollHeight;
    const scrollTopPosition: number = (<HTMLTableElement>target).scrollTop;

    const bufferAmount = 0;
    const limit: number = tableScrollHeight - tableViewHeight - bufferAmount;

    const hasReachedEnd: boolean = Math.min(limit, Math.ceil(scrollTopPosition)) >= limit;

    return hasReachedEnd;
  }
}
