import { StepName } from '@webtronic-labs/contracts-hip-hip';
/**
 * Temporary until flow totally implemented
 */
export const stepFlow: StepName[] = [
  StepName.WelcomeStep,
  StepName.BankRatesPreviewStep,
  StepName.CompleteProfileStep,
  StepName.CreditDetailsStep,
  StepName.FirstPaymentStep,
  StepName.FirstPaymentWaitingLobby,
  // Interaction from neep required
  StepName.NeepProvidedQuotesStep,
  // Universal Format Forms
  StepName.UniversalFormatExplanationStep,
  StepName.UniversalFormatGeneralDetailsStep,
  StepName.UniversalFormatSpouseDetailsStep,
  StepName.UniversalFormatWarrantyAddressStep,
  StepName.UniversalFormatCreditDetailsStep,
  StepName.UniversalFormatUploadDocumentsStep,
  // to design
  StepName.SecondPaymentStep,
  StepName.SecondPaymentWaitingLobby,
  StepName.WaitingFormReview,
  StepName.ThirdPaymentWaitingLobby,
  StepName.BankAppointmentStep,
  StepName.FinalStep,
];
