<div fxLayout="row">
  <div fxLayout="row" fxLayoutAlign="start center">
    <input [formControl]="searchControl" (keyup.enter)="searchValue()" />
    <button
      mat-icon-button
      class="u-bg-transparent"
      (click)="searchValue()"
      aria-label="search by menu button"
    >
      <mat-icon>search</mat-icon>
    </button>
  </div>
  <button
    mat-icon-button
    class="filter-button"
    [matMenuTriggerFor]="searchByMenu"
    aria-label="search by menu button"
  >
    <mat-icon class="material-icons-outlined">filter_alt</mat-icon>
  </button>
  <mat-menu #searchByMenu="matMenu">
    <button
      mat-menu-item
      *ngFor="let filter of filters"
      [ngClass]="{ 'filter-button--selected': selectedFilter.name === filter.name }"
      (click)="selectFilter(filter)"
    >
      <span>{{ filter.name }}</span>
    </button>
  </mat-menu>
</div>
