import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NavigateBackComponent } from './navigate-back/navigate-back.component';

const components = [NavigateBackComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, MatIconModule, FlexLayoutModule, RouterModule],
  exports: [...components],
})
export class NavigationComponentsModule {}
