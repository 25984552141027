import { Pipe, PipeTransform } from '@angular/core';
import { elapsedTime } from '../../core/utils';

@Pipe({
  name: 'elapsedTime',
})
export class ElapsedTimePipe implements PipeTransform {
  transform(value: number | Date): string {
    const date: number = new Date(value).getTime();
    const now: number = new Date().getTime();

    return elapsedTime(now, date);
  }
}
