import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IFilter } from '../../../core/interfaces';

@Component({
  selector: 'lib-search-bar-with-filters',
  templateUrl: './search-bar-with-filters.component.html',
  styleUrls: ['./search-bar-with-filters.component.scss'],
})
export class SearchBarWithFiltersComponent implements OnChanges {
  @Output()
  public search = new EventEmitter<string>();
  @Output()
  public applyFilter = new EventEmitter<IFilter>();

  @Input()
  filters: IFilter[] = [];

  selectedFilter: IFilter;

  public searchControl = new FormControl();

  public ngOnChanges(changes: SimpleChanges) {
    const currentFilters: IFilter[] = changes.filters.currentValue;

    if (currentFilters?.length > 0) {
      this.selectedFilter = currentFilters[0];

      this.applyFilter.emit(this.selectedFilter);
    }
  }

  public searchValue() {
    const valueToSearch: string = this.searchControl.value;

    this.search.emit(valueToSearch);
  }

  public selectFilter(filter: IFilter) {
    this.selectedFilter = filter;

    this.applyFilter.emit(this.selectedFilter);
  }
}
