<hip-modal>
  <div class="register-container">
    <form [formGroup]="registerForm" (ngSubmit)="submitRegisterForm()">
      <h3>Registrarse</h3>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="2rem">
        <div class="form-field">
          <label for="email">Email</label>
          <input
            formControlName="email"
            name="email"
            placeholder="ejemplo@mail.com"
            class="form-field__input"
            type="text"
          />

          <div *ngIf="emailControl.touched && emailControl.errors?.required">
            <p class="form-field__error">Requerido Email</p>
          </div>
          <div *ngIf="emailControl.touched && emailControl.errors?.pattern">
            <p class="form-field__error">Introduce un email válido</p>
          </div>
        </div>
      </div>

      <lib-password-input [isLargeInput]="true">
        <div errors>
          <div *ngIf="passwordControl.touched && passwordControl.errors?.required">
            <p class="form-field__error">Contraseña requerida</p>
          </div>
          <div *ngIf="passwordControl.touched && passwordControl.errors?.pattern">
            <p class="form-field__error">
              Al menos 8 caractéres, 1 mayúscula, 1 minúscula<br />
              y un número
            </p>
          </div>
        </div>
      </lib-password-input>

      <lib-password-input
        [isLargeInput]="true"
        passwordControlName="confirmPassword"
        passwordInputLabel="Confirmar Contraseña"
      >
        <div errors>
          <div *ngIf="confirmPasswordControl.touched && confirmPasswordControl.errors?.required">
            <p class="form-field__error">Confirmación requerida</p>
          </div>
          <div
            *ngIf="
              confirmPasswordControl.touched &&
              confirmPasswordControl.value !== passwordControl.value &&
              !confirmPasswordControl.errors?.required
            "
          >
            <p class="form-field__error">La contraseña no coincide</p>
          </div>
        </div>
      </lib-password-input>

      <div fxLayout="column" class="my-4">
        <div fxLayout="row" fxLayoutAlign="start center">
          <input
            class="form-checkbox__input"
            formControlName="acceptedTermsAndConditions"
            type="checkbox"
            name="policies"
          />
          <label for="policies">
            He leído y acepto la
            <span class="terms">
              <a (click)="showPrivacyPolicy()" class="button-link">
                Política de privacidad
              </a> </span
            ><br />
            y los
            <span class="terms">
              <a (click)="showTermsAndConditions()" class="button-link"> Términos & Condiciones </a>
            </span>
          </label>
        </div>
        <div *ngIf="acceptedTermsAndConditions.invalid && acceptedTermsAndConditions.touched">
          <p class="form-field__error">Debe aceptar los términos y condiciones</p>
        </div>
      </div>

      <p class="error-message font-md">
        {{ genericError }}
      </p>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2rem" class="mt-8">
        <button [disabled]="isProcessingRequest" type="submit" class="button">
          Completar Registro
        </button>
        <button type="button" class="button button--secondary" [routerLink]="['/auth']">
          Cancelar
        </button>
      </div>
    </form>
  </div>

  <div class="already-registered" fxLayout="row" fxLayoutGap="0.5rem">
    <mat-icon class="help-icon">help_outline</mat-icon>
    <p>
      Ya estás registrado?
      <span class="terms"><a class="button-link" [routerLink]="['/auth']">Inicia aquí</a></span>
      para continuar.
    </p>
  </div>
</hip-modal>
