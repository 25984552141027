const thresholds: { [key: string]: number } = {
  ss: 30, // a few seconds to seconds
  s: 50, // seconds to minute
  m: 55, // minutes to hour
  h: 23, // hours to day
  d: 28, // days to month
  M: 11.5, // months to year
};

export const monthDiff = (d1: Date, d2: Date): number => {
  let months: number;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();

  return Math.abs(months);
};

export const elapsedTime = (time1: number, time2: number): string => {
  const date: Date = new Date(time2);
  const now: Date = new Date(time1);
  const diffInSeconds: number = (now.getTime() - date.getTime()) / 1000;

  if (diffInSeconds <= thresholds.ss) {
    return 'hace unos segundos';
  }

  if (diffInSeconds <= thresholds.s) {
    return `hace ${diffInSeconds.toFixed(0)} segundos`;
  }

  const diffInMinutes: number = diffInSeconds / 60;

  if (diffInMinutes <= thresholds.m) {
    return `hace ${diffInMinutes.toFixed(0)} minutos`;
  }

  const diffInHours: number = diffInSeconds / 60;

  if (diffInHours <= 1) {
    return 'hace una hora';
  }

  if (diffInHours <= thresholds.h) {
    return `hace ${diffInHours.toFixed(0)} horas`;
  }

  const diffInDays: number = diffInSeconds / 24;

  if (diffInDays <= 1) {
    return 'hace un día';
  }

  if (diffInDays <= thresholds.d) {
    return `hace ${diffInDays.toFixed(0)} días`;
  }

  const diffInMonths: number = monthDiff(now, date);

  if (diffInMonths <= 1) {
    return 'hace un mes';
  }

  if (diffInMonths <= thresholds.M) {
    return `hace ${diffInMonths.toFixed(0)} meses`;
  }

  const diffInYears: number = now.getFullYear() - date.getFullYear();

  if (diffInYears <= 1) {
    return 'hace un año';
  }

  return `hace ${diffInYears} años`;
};
