import { createReducer, on } from '@ngrx/store';
import { HipUser } from '@webtronic-labs/contracts-hip-hip';
import * as AuthActionTypes from './auth.actions';

export const authFeatureKey = 'authState';

export interface IAuthState {
  currentHipUser: HipUser;
}

export const authInitialState: IAuthState = {
  currentHipUser: null,
};

export const authReducer = createReducer(
  authInitialState,
  on(AuthActionTypes.setCurrentHipUser, (state: IAuthState, action) => {
    return { ...state, currentHipUser: action.user };
  })
);
