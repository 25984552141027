<div fxLayout="row" fxLayoutAlign="end center" class="auth-container">
  <div fxShow fxHide.xs fxLayout="column"></div>

  <div
    class="auth-box"
    fxLayout="column"
    fxLayoutAlign="center start"
    fxLayoutAlign.lt-sm="start center"
  >
    <img src="/assets/img/logo.svg" alt="" class="auth-box__logo" />
    <div fxLayout="row" fxLayoutAlign="center center" class="w-full">
      <h3 *ngIf="isNeepLogin" class="subtitle">NEEP</h3>
    </div>

    <div class="info-box font-sm-light">
      Inicia sesión
      <ng-container *ngIf="!isNeepLogin">o regístrate.</ng-container>
    </div>
    <div fxLayout="column">
      <form [formGroup]="loginForm" (ngSubmit)="submitLoginForm()" class="login-form">
        <div class="form-field">
          <label class="font-md">Usuario</label>
          <input
            name="email"
            autocomplete="email"
            formControlName="email"
            placeholder="name@domain.com"
            class="form-field__input"
            type="text"
          />
          <p class="error-message" *ngIf="emailControl.errors?.required && passwordControl.dirty">
            email requerido
          </p>
          <p class="error-message" *ngIf="emailControl.errors?.pattern">
            debe introducir un email válido
          </p>
        </div>

        <div class="password-input-container">
          <lib-password-input [isLargeInput]="true">
            <div errors>
              <p
                class="error-message"
                *ngIf="passwordControl.errors?.required && passwordControl.dirty"
              >
                password requerido
              </p>
              <p class="error-message" *ngIf="passwordControl.errors?.minlength">
                8 caracteres mínimo<br />
                para la contraseña
              </p>
            </div>
          </lib-password-input>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
          <a
            class="button-link"
            [routerLink]="['password-recovery']"
            [queryParams]="{ email: emailControl.value }"
            >Olvidé mi contraseña</a
          >
        </div>

        <div *ngIf="resultErrorMessage?.length" class="errors-container font-sm">
          {{ resultErrorMessage }}
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-sm="column"
          fxLayoutGap="1rem"
          fxLayoutAlign="space-between center"
          class="login-buttons"
        >
          <button [disabled]="loginForm.invalid" type="submit" class="button">Entrar</button>
          <button
            *ngIf="!isNeepLogin"
            class="button button--secondary"
            type="button"
            [routerLink]="['register']"
            (click)="hideTermsAndConditions()"
          >
            Registrarse
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
