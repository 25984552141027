<div class="header-container" fxLayout="row" fxLayoutAlign="center center" class="px-4">
  <div fxShow fxHide.lt-md fxFlex="33%" class="spacing">
    <div fxLayout="row" fxLayoutGap="1rem" *ngIf="hasNavigationControls">
      <button
        fxLayout="row"
        fxLayoutAlign="center center"
        class="navigation-button"
        [ngClass]="{ 'navigation-button--active': canGoBack }"
        (click)="goBackClicked()"
      >
        <mat-icon class="arrow" [ngClass]="{ 'arrow--active': canGoBack }">arrow_backward</mat-icon>
      </button>
      <button
        fxLayout="row"
        fxLayoutAlign="center center"
        class="navigation-button"
        [ngClass]="{ 'navigation-button--active': canAdvance }"
        (click)="advanceClicked()"
      >
        <mat-icon class="arrow" [ngClass]="{ 'arrow--active': canAdvance }">arrow_forward</mat-icon>
      </button>
    </div>
  </div>
  <div
    fxFlex.lt-md="50%"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxFlex="33%"
    class="logo-container"
  >
    <img src="/assets/img/logo.svg" alt="" class="home-logo" fxShow fxHide.lt-md />
    <img src="/assets/img/logo-mobile.png" alt="" class="home-logo--mobile" fxHide fxShow.lt-md />
  </div>
  <div
    fxFlex="33%"
    fxFlex.lt-md="50%"
    class="right-panel"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="2rem"
  >
    <lib-notifications-panel
      (notificationClicked)="notificationClicked.emit($event)"
    ></lib-notifications-panel>

    <a class="bold-link font-xs-bold" (click)="logout()">Cerrar Sesión</a>
  </div>
</div>
