import { monthDiff } from './elapsed-time';

export const getAge = (date: Date | string): number => {
  const birthDate = new Date(date);
  const currentDate = new Date();
  const elapsedTimeInMonth: number = monthDiff(currentDate, birthDate);

  const age: number = Math.floor(elapsedTimeInMonth / 12);

  return age;
};
