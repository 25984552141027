import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { IDocumentToUpload } from '../../../core/interfaces';
import { roundFileSize } from '../../../core/utils';

@Component({
  selector: 'lib-document-pool',
  templateUrl: './document-pool.component.html',
  styleUrls: ['./document-pool.component.scss'],
})
export class DocumentPoolComponent {
  @Output()
  public documentUploaded = new EventEmitter<IDocumentToUpload>();
  @Output()
  public documentRemoved = new EventEmitter<IDocumentToUpload>();
  @Output()
  public documentsChanged = new EventEmitter<IDocumentToUpload[]>();

  @Input()
  public title = 'Documentos Anexos';
  @Input()
  public userFilesToUpload: IDocumentToUpload[] = [];

  private documentUploading: IDocumentToUpload;
  private defaultInputFormats =
    '.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.jpg,.jpeg,.png';

  @ViewChild('fileInput')
  private fileInput: ElementRef;

  constructor(private renderer: Renderer2) {}

  public uploadFile(documentUploading: IDocumentToUpload): void {
    const inputFormat = documentUploading.documentFormat
      ? documentUploading.documentFormat
      : this.defaultInputFormats;
    this.renderer.setAttribute(this.fileInput.nativeElement, 'accept', inputFormat);

    this.fileInput.nativeElement.click();
    this.documentUploading = documentUploading;
  }

  public uploadFilesStatusChange(event: Event): void {
    const files = (<HTMLInputElement>event.target).files;
    const firstFile: File = files[0];

    if (firstFile) {
      const documentUploadingUpdated: IDocumentToUpload = {
        ...this.documentUploading,
        hasRequiredError: false,
        file: firstFile,
      };

      (this.fileInput.nativeElement as HTMLInputElement).value = null;

      this.updateDocument(documentUploadingUpdated, firstFile);
      this.documentUploaded.emit(documentUploadingUpdated);
      this.documentsChanged.emit(this.userFilesToUpload);
    }
  }

  public removeAttachment(documentToBeRemoved: IDocumentToUpload): void {
    this.updateDocument(documentToBeRemoved, null);
    this.documentRemoved.emit(documentToBeRemoved);
    this.documentsChanged.emit(this.userFilesToUpload);
  }

  public roundFileSize(size: number): string {
    return roundFileSize(size);
  }

  private updateDocument(documentToBeUpdated: IDocumentToUpload, file: File): void {
    const index: number = this.userFilesToUpload.findIndex(
      (document: IDocumentToUpload) =>
        document.title === documentToBeUpdated.title &&
        document.documentType === documentToBeUpdated.documentType
    );

    this.userFilesToUpload[index] = {
      ...this.userFilesToUpload[index],
      ...documentToBeUpdated,
      file,
    };
  }
}
