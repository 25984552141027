import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  FirebaseCollections,
  HipUser,
  ICreateUserResponse,
  IGenericApiResponse,
  IPhoneNumberCheckResponse,
  IUrlResponse,
  StorageFileNames,
} from '@webtronic-labs/contracts-hip-hip';
import firebase from 'firebase/app';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { setCurrentHipUser } from '../../auth/store-auth/auth.actions';
import { selectHipUser } from '../../auth/store-auth/auth.selectors';
import { FirebaseService, selectFirebaseUser } from '../../firebase';

@Injectable({
  providedIn: 'root',
})
export class HipUserService {
  private activeSubBuffer: Observable<HipUser>;

  constructor(
    private store: Store,
    private firebaseService: FirebaseService,
    private http: HttpClient
  ) {
    this.currentUserSub();
  }

  public getUser(userId: string): Observable<HipUser> {
    return this.http.get<HipUser>(`${environment.apiUrl}/users/user/${userId}`);
  }

  public getFileUrl(fileName: string): Observable<string> {
    return this.http
      .get<IUrlResponse>(`${environment.apiUrl}/user-storage/file-url/${fileName}`)
      .pipe(map((fileUrl: IUrlResponse) => fileUrl.url as string));
  }

  public selectCurrentHipUser(): Observable<HipUser> {
    if (this.activeSubBuffer) {
      return this.activeSubBuffer;
    }

    this.activeSubBuffer = this.store.pipe(
      select(selectHipUser),
      switchMap((hipUser: HipUser) => {
        if (hipUser) {
          return of(hipUser);
        }

        return this.store.pipe(
          select(selectFirebaseUser),
          switchMap((firebaseUser: firebase.User) => {
            return this.firebaseService.getDoc(FirebaseCollections.Users, firebaseUser.uid).pipe(
              tap((hipUser: HipUser) => {
                this.store.dispatch(setCurrentHipUser({ user: hipUser }));
              })
            );
          }),
          take(1)
        );
      })
    );

    return this.activeSubBuffer;
  }

  public checkPhoneNumberAvailability(phoneNumber: string): Observable<boolean> {
    return this.http
      .post<IPhoneNumberCheckResponse>(`${environment.apiUrl}/validation/phone-exists`, {
        phoneNumber,
      })
      .pipe(
        map(
          (phoneNumberCheckResponse: IPhoneNumberCheckResponse) =>
            phoneNumberCheckResponse.phoneExists
        )
      );
  }

  public createNeepUser(user: Partial<HipUser>): Observable<ICreateUserResponse> {
    return this.http.post<ICreateUserResponse>(`${environment.apiUrl}/users/create-neep-user`, {
      ...user,
    });
  }

  public updateUserFirstLogin(userId: string): Observable<IGenericApiResponse> {
    return this.http.patch<IGenericApiResponse>(
      `${environment.apiUrl}/users/update-first-login/${userId}`,
      null
    );
  }

  public uploadProfilePicture(userId: string, profilePictureFile: File): Observable<void> {
    const formData = new FormData();

    formData.append(StorageFileNames.ProfilePicture, profilePictureFile);

    return this.http.post<void>(
      `${environment.apiUrl}/user-storage/profile-picture/${userId}`,
      formData
    );
  }

  public getProfilePicture(userId: string): Observable<string> {
    return this.http
      .get<IUrlResponse>(`${environment.apiUrl}/user-storage/profile-picture/${userId}`)
      .pipe(map((urlResponse: IUrlResponse) => urlResponse.url as string));
  }

  private currentUserSub() {
    // this subscription is to keep up to day the current HipUser when we logout and login with other user
    this.store
      .pipe(
        select(selectFirebaseUser),
        switchMap((firebaseUser: firebase.User) => {
          if (!firebaseUser) {
            return of(null);
          }

          return this.firebaseService.getDoc(FirebaseCollections.Users, firebaseUser.uid);
        })
      )
      .subscribe({
        next: (hipUser: HipUser) => {
          if (hipUser) {
            this.store.dispatch(setCurrentHipUser({ user: hipUser }));
          }
        },
      });
  }
}
