import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { LocalStorageKeys } from '../../core/enums';

@Component({
  selector: 'hip-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent {
  @Input()
  public mustSelectOption = false;

  constructor(private location: Location) {}

  public goBack(): void {
    this.location.back();
  }

  public acceptTerms(): void {
    localStorage.setItem(LocalStorageKeys.AcceptedTerms, 'true');
    this.location.back();
  }
}
