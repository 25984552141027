import { createReducer, on } from '@ngrx/store';
import { IUserNotification } from '@webtronic-labs/contracts-hip-hip';
import * as NotificationsActionTypes from './notifications.actions';

export const notificationsFeatureKey = 'notificationsState';

export interface INotificationsState {
  currentNotifications: IUserNotification[];
}

export const notificationsInitialState: INotificationsState = {
  currentNotifications: [],
};

export const notificationsReducer = createReducer(
  notificationsInitialState,
  on(NotificationsActionTypes.setNotifications, (state: INotificationsState, action) => {
    return { ...state, currentNotifications: action.notifications };
  })
);
