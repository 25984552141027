import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { CaptureConsole } from '@sentry/integrations';

Sentry.init({
  environment: environment.sentryConfiguration.environment,
  dsn: environment.sentryConfiguration.dsn,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: environment.sentryConfiguration.tracingOrigins,
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    // this send all console.error to sentry
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    preserveWhitespaces: false,
  })
  .catch((err) => console.error(err));
