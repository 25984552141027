// Add the directive in any input you don't want white spaces
// <input hipNoSpaces/>

import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
@Directive({
  selector: '[hipNoSpaces]',
})
export class NoSpacesDirective {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event'])
  public onInput() {
    const convertedInputValue: string = this.elementRef.nativeElement.value.replace(/\s/g, '');
    this.renderer.setValue(this.elementRef.nativeElement, convertedInputValue);
  }
}
