import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import 'firebase/auth';
import { from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FirebaseService } from '../../firebase.service';

@Injectable()
export class FirebaseLoggedIn implements CanActivate, CanActivateChild, CanLoad {
  constructor(private router: Router, private firebaseService: FirebaseService) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canEnterTheRoute(next, state);
  }

  public canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canEnterTheRoute();
  }

  public canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canEnterTheRoute();
  }

  private canEnterTheRoute(
    next: ActivatedRouteSnapshot = null,
    state: RouterStateSnapshot = null
  ): Observable<boolean> | Promise<boolean> | boolean {
    return from(this.firebaseService.isLoggedIn()).pipe(
      map((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.router.navigate(['auth']);
        }

        return isLoggedIn;
      })
    );
  }
}
