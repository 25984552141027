import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IUserNotification, UserNotificationStatus } from '@webtronic-labs/contracts-hip-hip';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { NotificationsService } from '../notifications.service';
import { HttpErrorResponse } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import {
  selectReadNotifications,
  selectUnreadNotifications,
} from '../notifications-store/notifications.selectors';

@Component({
  selector: 'lib-notifications-panel',
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss'],
})
export class NotificationsPanelComponent implements OnInit, OnDestroy {
  @Output()
  public notificationClicked = new EventEmitter<IUserNotification>();

  public currentNotifications: IUserNotification[] = [];
  public isPanelOpen = false;
  public unreadNotifications: IUserNotification[] = [];
  public availableNotificationStatuses = UserNotificationStatus;
  public isProcessing = false;
  public selectedTabIndex = 0;

  private destroy$ = new Subject<void>();

  constructor(
    private notificationsService: NotificationsService,
    private store: Store,
    private element: ElementRef
  ) {}

  public ngOnInit(): void {
    this.currentNotificationsSub();
  }

  public togglePanel() {
    this.selectedTabIndex = 0;
    this.isPanelOpen = !this.isPanelOpen;
  }

  public removeNotification(id: string) {
    this.isProcessing = true;
    this.notificationsService
      .deleteNotification(id)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.isProcessing = false;
        },
      });
  }

  public acknowledgeNotification(id: string) {
    this.isProcessing = true;
    const notification: IUserNotification = this.unreadNotifications.find(
      (unreadNotification: IUserNotification) => id === unreadNotification.id
    );

    this.notificationClicked.emit(notification);

    this.notificationsService
      .acknowledgeNotification(id)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.isProcessing = false;
        },
      });
  }

  public cleanNotifications() {
    this.isProcessing = true;
    if (this.selectedTabIndex) {
      const notificationIds: string[] = this.currentNotifications.map(
        (notification: IUserNotification) => notification.id
      );

      this.notificationsService
        .deleteNotifications(notificationIds)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.isProcessing = false;
          },
          error: (err: HttpErrorResponse) => {
            console.error(err);
          },
        });

      return;
    }

    const notificationIds: string[] = this.unreadNotifications.map(
      (notification: IUserNotification) => notification.id
    );

    this.notificationsService
      .acknowledgeNotifications(notificationIds)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.isProcessing = false;
        },
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private currentNotificationsSub() {
    this.store.pipe(select(selectReadNotifications), takeUntil(this.destroy$)).subscribe({
      next: (notifications: IUserNotification[]) => {
        this.currentNotifications = notifications;
      },
    });

    this.store.pipe(select(selectUnreadNotifications), takeUntil(this.destroy$)).subscribe({
      next: (notifications: IUserNotification[]) => {
        this.unreadNotifications = notifications;
      },
    });
  }

  @HostListener('document:click', ['$event'])
  private clickOutside(event: MouseEvent) {
    if (!this.isPanelOpen) {
      return;
    }

    const clickTarget: HTMLElement = event.target as HTMLElement;

    const isOrContainsClickTarget: boolean =
      this.element.nativeElement === clickTarget ||
      this.element.nativeElement?.contains(clickTarget);

    if (!isOrContainsClickTarget) {
      this.isPanelOpen = false;
    }
  }
}
