import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  emailValidator,
  FirebaseCollections,
  HipUser,
  pwdChars8letters1numbers1,
} from '@webtronic-labs/contracts-hip-hip';
import firebase from 'firebase/app';
import 'firebase/auth';
import { mapTo, switchMap, take } from 'rxjs/operators';
import {
  FirebaseErrorMessages,
  FirebaseRegisterErrorType,
  LocalStorageKeys,
} from '../../core/enums';
import { InMemoryStorageService } from '../../core/services/in-memory-storage.service';
import { FirebaseService } from '../../firebase';

@Component({
  selector: 'hip-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public genericError = '';
  public registerForm: FormGroup;
  public areTermsAccepted = false;
  public isProcessingRequest = false;
  public shouldShowPassword = false;

  private savedRegisterForm: Record<string, string>;

  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    private inMemoryStorageService: InMemoryStorageService
  ) {}

  public ngOnInit(): void {
    this.getSavedRegisterForm();
    this.initializeRegisterForm();
  }

  public toggleTerms(): void {
    this.areTermsAccepted = !this.areTermsAccepted;
    this.inMemoryStorageService
      .setKey(LocalStorageKeys.AcceptedTerms, this.areTermsAccepted)
      .pipe(take(1))
      .subscribe();
  }

  public togglePasswordVisibility(): void {
    this.shouldShowPassword = !this.shouldShowPassword;
  }

  public submitRegisterForm(): void {
    const isFormInvalid: boolean =
      this.isProcessingRequest ||
      this.registerForm.invalid ||
      this.passwordControl.value !== this.confirmPasswordControl.value;

    if (isFormInvalid) {
      this.markControlsAsTouched();

      return;
    }

    const generatedUser: Partial<HipUser> = this.generatePartialUser();

    this.genericError = '';
    this.isProcessingRequest = true;

    this.firebaseService
      .register(this.emailControl.value, this.passwordControl.value)
      .pipe(
        switchMap((credentials: firebase.auth.UserCredential) => {
          return this.inMemoryStorageService
            .removeKey(LocalStorageKeys.SavedRegisterForm)
            .pipe(mapTo(credentials));
        }),
        take(1)
      )
      .subscribe({
        next: (credentials: firebase.auth.UserCredential) => {
          const userId: string = credentials.user.uid;
          this.inMemoryStorageService.removeKey(LocalStorageKeys.SavedRegisterForm);
          this.firebaseService.writeToDatabase(FirebaseCollections.Users, userId, generatedUser);
          // Auto login after successful registration
          this.loginAfterRegister(this.emailControl.value, this.passwordControl.value);
        },
        error: (errorCreatingUser: firebase.FirebaseError) => {
          this.isProcessingRequest = false;

          this.genericError = errorCreatingUser.message;

          if (errorCreatingUser.code === FirebaseRegisterErrorType.EmailAlreadyInUse) {
            this.genericError = FirebaseErrorMessages.EmailAlreadyInUse;
          }
        },
      });
  }

  public showTermsAndConditions(): void {
    const savedRegisterForm: Record<string, string> = this.registerForm.getRawValue();

    this.inMemoryStorageService
      .setKey(LocalStorageKeys.SavedRegisterForm, savedRegisterForm)
      .pipe(take(1))
      .subscribe();

    this.router.navigate(['/terms-and-conditions']);
  }

  public showPrivacyPolicy(): void {
    const savedRegisterForm: Record<string, string> = this.registerForm.getRawValue();

    this.inMemoryStorageService
      .setKey(LocalStorageKeys.SavedRegisterForm, savedRegisterForm)
      .pipe(take(1))
      .subscribe();

    this.router.navigate(['/privacy-policy']);
  }

  // Form Controls
  public get emailControl(): AbstractControl {
    return this.registerForm.get('email');
  }

  public get passwordControl(): AbstractControl {
    return this.registerForm.get('password');
  }

  public get confirmPasswordControl(): AbstractControl {
    return this.registerForm.get('confirmPassword');
  }

  public get acceptedTermsAndConditions(): AbstractControl {
    return this.registerForm.get('acceptedTermsAndConditions');
  }

  // Form initialization
  private initializeRegisterForm(): void {
    this.registerForm = new FormGroup({
      email: new FormControl(this.savedRegisterForm?.email || null, [
        Validators.required,
        Validators.pattern(emailValidator),
      ]),
      password: new FormControl(this.savedRegisterForm?.password || null, [
        Validators.required,
        Validators.pattern(pwdChars8letters1numbers1),
        Validators.minLength(5),
      ]),
      confirmPassword: new FormControl(null, [
        Validators.required,
        Validators.pattern(pwdChars8letters1numbers1),
        Validators.minLength(5),
      ]),
      acceptedTermsAndConditions: new FormControl(this.areTermsAccepted, [Validators.requiredTrue]),
    });
  }

  private getSavedRegisterForm(): void {
    this.inMemoryStorageService
      .getKey<Record<string, string>>(LocalStorageKeys.SavedRegisterForm)
      .pipe(take(1))
      .subscribe({
        next: (savedRegisterForm: Record<string, string>) => {
          this.savedRegisterForm = savedRegisterForm;
        },
      });

    this.inMemoryStorageService
      .getKey<boolean>(LocalStorageKeys.AcceptedTerms)
      .pipe(take(1))
      .subscribe({
        next: (areTermsAccepted: boolean) => {
          this.areTermsAccepted = areTermsAccepted || false;
        },
      });
  }

  private generatePartialUser(): Partial<HipUser> {
    const generatedUser: Partial<HipUser> = {
      createdAt: new Date().getTime(),
      email: this.emailControl.value,
    };

    return generatedUser;
  }

  private markControlsAsTouched(): void {
    // iterate over controls in the form group and mark as touched
    // we mark the controls as touched to trigger the validation of each control
    for (const field of Object.keys(this.registerForm.controls)) {
      const control: AbstractControl = this.registerForm.get(field);
      control.markAsTouched({ onlySelf: true });
    }
  }

  private loginAfterRegister(username: string, password: string): void {
    this.firebaseService
      .login(username, password)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.router.navigate(['/']);
        },
      });
  }
}
