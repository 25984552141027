export interface IEnvironment {
  appVersion: string;
  production: boolean;
  isDemo: boolean;
  firebaseConfig: IFirebaseConfig;
  apiUrl: string;
  googleStaticMapsApiKey?: string;
  // Sentry
  sentryConfiguration: {
    environment: string;
    dsn: string;
    tracingOrigins: string[];
    /**
     * Chance of a tracing sample to be sent to the server, reduced in production
     * for performance and network reasons
     */
    tracesSampleRate: number;
  };
}

interface IFirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string;
}
