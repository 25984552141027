import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

/**
 * This directive allows adding a class based on the current route
 * Use as:
 *  <div [webClassForRoute]="{routes: ['/admin']; classToToggle: 'hidden'; shouldRemoveInstead: false}">
      // Content
    </div>
 */
@Directive({
  selector: '[webClassForRoute]',
})
export class ClassForRouteDirective implements OnDestroy, OnInit {
  @Input()
  public webClassForRoute: {
    routes: string[];
    classToToggle: string;
    shouldRemoveInstead?: boolean;
  } = null;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  public ngOnInit() {
    this.isInAcceptedRoute(this.router.url);
    this.activeRouteSub();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private isInAcceptedRoute(currentURL: string) {
    let isInAffectedRoute = false;

    for (const affectedRoutes of this.webClassForRoute.routes) {
      const regex = new RegExp(affectedRoutes);

      if (regex.exec(currentURL)) {
        isInAffectedRoute = true;
        break;
      }
    }

    if (this.webClassForRoute.shouldRemoveInstead) {
      isInAffectedRoute = !isInAffectedRoute;
    }

    if (isInAffectedRoute) {
      this.renderer.addClass(this.elementRef.nativeElement, this.webClassForRoute.classToToggle);
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, this.webClassForRoute.classToToggle);
    }
  }

  private activeRouteSub() {
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (change: NavigationEnd) => {
          this.isInAcceptedRoute(change.url);
        },
      });
  }
}
