import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-expanded-header',
  templateUrl: './expanded-header.component.html',
  styleUrls: ['./expanded-header.component.scss'],
})
export class ExpandedHeaderComponent {
  @Input()
  public isExpanded = false;

  public toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
