import { CommonModule } from '@angular/common';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HipLoadersModule } from '../../projects/hip-lib/src/lib/loaders/loaders.module';
import {
  authFeatureKey,
  authReducer,
} from '../../projects/hip-lib/src/lib/auth/store-auth/auth-state.reducer';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import {
  appRootFeatureKey,
  appRootReducer,
} from 'projects/hip-lib/src/lib/store-app-root/app-root-state.reducer';
import {
  bankDetailsReducer,
  banksDetailsFeatureKey,
} from 'projects/hip-lib/src/lib/bank-information/store-banks-details/banks-details-state.reducer';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { PublicViewsModule } from 'projects/hip-lib/src/lib/public-views/public-views.module';

// by default locale is en, since we are using date pipe to show months names
// we want to be in spanish by default
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    CoreModule,
    AppRoutingModule,
    HipLoadersModule,
    StoreModule.forFeature(appRootFeatureKey, appRootReducer),
    StoreModule.forFeature(authFeatureKey, authReducer),
    StoreModule.forFeature(banksDetailsFeatureKey, bankDetailsReducer),
    PublicViewsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
})
export class AppModule {}
