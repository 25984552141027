import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { FirebaseLoggedIn } from './core/guards/firebase-logged.guard';
import { firebaseFeatureKey, firebaseReducer } from './store-firebase/firebase-state.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(firebaseFeatureKey, firebaseReducer)],
  providers: [FirebaseLoggedIn],
})
export class FirebaseModule {}
