import { Component, OnInit } from '@angular/core';
import { HipUser } from '@webtronic-labs/contracts-hip-hip';
import { CountryCode } from 'projects/neep/src/app/core/enums';
import { take } from 'rxjs';
import { hipHipWhatsAppNumber, whatsAppSendMessageApi } from '../../../core/constants';
import { HipUserService } from '../../../core/services/hip-user.service';

@Component({
  selector: 'lib-help-information',
  templateUrl: './help-information.component.html',
  styleUrls: ['./help-information.component.scss'],
})
export class HelpInformationComponent implements OnInit {
  public whatsappNumber: string = hipHipWhatsAppNumber;
  public whatsAppSendMessageApi: string = whatsAppSendMessageApi;

  constructor(private hipUserService: HipUserService) {}

  public ngOnInit(): void {
    this.getNeepPhoneNumber();
  }

  private getNeepPhoneNumber() {
    this.hipUserService
      .selectCurrentHipUser()
      .pipe(take(1))
      .subscribe({
        next: (currentUser: HipUser) => {
          if (currentUser?.assignedNeep) {
            const phoneNumber: string = currentUser.assignedNeep.phoneNumber;
            this.whatsappNumber =
              phoneNumber[0] === '+' ? phoneNumber : CountryCode.MEXICO + phoneNumber;
          }
        },
      });
  }
}
