import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HipPopupsModule } from '../../public-api';
import { HipComponentsModule } from '../components/hip-components.module';
import { PrivacyPolicyPublicComponent } from './privacy-policy-public/privacy-policy-public.component';
import { TermsAndConditionsPublicComponent } from './terms-and-conditions-public/terms-and-conditions-public.component';

const components = [PrivacyPolicyPublicComponent, TermsAndConditionsPublicComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, HipComponentsModule, HipPopupsModule],
  exports: [...components],
})
export class PublicViewsModule {}
