import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { PrivacyModalComponent } from './privacy-modal/privacy-modal.component';
import { RegisterComponent } from './register/register.component';
import { TermsModalComponent } from './terms-modal/terms-modal.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
        children: [
          {
            path: 'register',
            component: RegisterComponent,
          },
          {
            path: 'password-recovery',
            component: PasswordRecoveryComponent,
          },
          {
            path: 'password-change',
            component: PasswordChangeComponent,
          },
          {
            path: 'terms-and-conditions',
            component: TermsModalComponent,
          },
          {
            path: 'privacy-policy',
            component: PrivacyModalComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
