import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'lib-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  viewProviders: [
    // this viewProviders are needs in order to formControlName in the input can
    // access to the properly field of the FormGroup of the parent
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class PasswordInputComponent {
  @Input()
  public passwordControlName = 'password';
  @Input()
  public passwordInputLabel = 'Contraseña';
  @Input()
  public details = '';
  @Input()
  public isLargeInput = false;

  public shouldShowPassword = false;

  public togglePasswordVisibility() {
    this.shouldShowPassword = !this.shouldShowPassword;
  }
}
