import { createReducer, on } from '@ngrx/store';
import * as AppRootActionTypes from './app-root.actions';

export const appRootFeatureKey = 'appRootState';

export interface IAppRootState {
  version: string;
  isFullScreenLoading: boolean;
  isLoadingCurrentStep: boolean;
}

export const appRootInitialState: IAppRootState = {
  version: '0.0.0',
  isFullScreenLoading: true,
  isLoadingCurrentStep: true,
};

export const appRootReducer = createReducer(
  appRootInitialState,
  on(AppRootActionTypes.setVersion, (state: IAppRootState, action) => {
    return { ...state, version: action.version };
  }),
  on(AppRootActionTypes.setIsFullScreenLoading, (state: IAppRootState, action) => {
    return { ...state, isFullScreenLoading: action.isLoading };
  }),
  on(AppRootActionTypes.setIsLoadingCurrentStep, (state: IAppRootState, action) => {
    return { ...state, isLoadingCurrentStep: action.isLoading };
  })
);
