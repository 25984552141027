import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import {
  setIsFullScreenLoading,
  setIsLoadingCurrentStep,
} from '../../store-app-root/app-root.actions';
import {
  selectIsFullScreenLoading,
  selectIsLoadingCurrentStep,
} from '../../store-app-root/app-root.selectors';
import { skipThread } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class AppLoadingService {
  constructor(private store: Store) {}

  // this function return the store selector to avid inject store in the component and import selector
  public selectIsFullScreenLoading(): Observable<boolean> {
    return this.store.pipe(select(selectIsFullScreenLoading));
  }

  // this function return the store selector to avid inject store in the component and import selector
  public selectIsLoadingCurrentStep(): Observable<boolean> {
    return this.store.pipe(select(selectIsLoadingCurrentStep));
  }

  public setFullScreenLoading(isLoading: boolean) {
    this.store.dispatch(setIsFullScreenLoading({ isLoading }));
  }

  public setIsLoadingCurrentStep(isLoading: boolean) {
    this.store.dispatch(setIsLoadingCurrentStep({ isLoading }));
  }

  public setFullScreenLoadingAsync(isLoading: boolean) {
    skipThread()
      .pipe(take(1))
      .subscribe(() => {
        this.store.dispatch(setIsFullScreenLoading({ isLoading }));
      });
  }
}
