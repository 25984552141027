// this split last name properly, handle cases like `De Armas Last` and `De La Caridad Last`
// ex: `De Armas Last` -> ['De Armas', 'Last'] , `De La Caridad Last`-> ['De La Caridad', 'Last']
export const splitName = (name: string): string[] => {
  if (!name) {
    return null;
  }

  const splittedName: string[] = name.split(' ');

  // return immediately if we only have two names
  if (splittedName.length <= 2) {
    return splittedName;
  }

  let firstName: string = splittedName[0];
  let secondName: string;

  // check if the first part is not a monosyllable like `De`, `Del`, 'La'
  // since al monosyllable have maximum 3 characters
  if (firstName.length > 3) {
    secondName = splittedName.slice(1).join(' ');

    return [firstName, secondName];
  }

  // check if is splitted in more than 3 parts like `De La Caridad Test`
  // and the second part is a monosyllable
  if (splittedName.length > 3 && splittedName[1].length <= 3) {
    firstName = `${firstName} ${splittedName[1]} ${splittedName[2]}`;
    secondName = splittedName.slice(3).join(' ');

    return [firstName, secondName];
  }

  firstName = `${firstName} ${splittedName[1]}`;
  secondName = splittedName.slice(2).join(' ');

  return [firstName, secondName];
};
