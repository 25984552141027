import { DOCUMENT } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FirebaseCollections } from '@webtronic-labs/contracts-hip-hip';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { from, Observable, of, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import { firebaseConfig } from './core/config';
import { IFirebaseReadFromCollectionParams } from './core/interfaces';
import { setFirebaseUser } from './store-firebase/firebase.actions';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private firebaseApp: firebase.app.App;
  private subscriptions: { [key: string]: (() => void)[] } = {};

  constructor(private store: Store, @Inject(DOCUMENT) private document: Document) {
    this.firebaseApp = this.initFirebaseIfNeeded();

    this.firebaseApp.auth().onAuthStateChanged((user: firebase.User) => {
      const userId: string = user?.uid;

      if (!!user && !this.subscriptions[userId]) {
        this.subscriptions[userId] = [];
      }

      const userCopy: firebase.User = JSON.parse(JSON.stringify(user));
      this.store.dispatch(setFirebaseUser({ user: userCopy }));
    });
  }

  // Auth
  public getCurrentUser(): firebase.User {
    return this.firebaseApp.auth().currentUser;
  }

  public isLoggedIn(): Promise<boolean> {
    return new Promise((resolve) => {
      const unsubscribe = this.firebaseApp.auth().onAuthStateChanged((user: firebase.User) => {
        resolve(!!user);
        unsubscribe();
      });
    });
  }

  public login(email: string, password: string) {
    return from(this.firebaseApp.auth().signInWithEmailAndPassword(email, password));
  }

  public register(email: string, password: string) {
    return from(this.firebaseApp.auth().createUserWithEmailAndPassword(email, password));
  }

  public logout() {
    this.cleanSubscriptions();
    this.firebaseApp.auth().signOut();
    this.store.dispatch(
      setFirebaseUser({
        user: null,
      })
    );
  }

  public sendRecoveryEmail(email: string): Observable<void> {
    const clientUrl = this.document.location.origin;
    const actionCodeSettings: firebase.auth.ActionCodeSettings = {
      url: clientUrl,
      handleCodeInApp: false,
    };

    return from(this.firebaseApp.auth().sendPasswordResetEmail(email.trim(), actionCodeSettings));
  }

  public changePassword(code: string, newPassword: string): Observable<void> {
    return from(this.firebaseApp.auth().confirmPasswordReset(code, newPassword));
  }

  public verifyPasswordResetCode(code: string): Observable<string> {
    return from(this.firebaseApp.auth().verifyPasswordResetCode(code));
  }

  public updatePassword(newPassword: string): Observable<void> {
    return from(this.firebaseApp.auth().currentUser.updatePassword(newPassword));
  }

  public getAuthHeaders(): Observable<HttpHeaders> {
    return this.getFirebaseToken().pipe(
      map((token: string) => {
        const headers = new HttpHeaders({
          Authorization: 'Bearer ' + token,
        });

        return headers;
      })
    );
  }

  // Database
  public writeToDatabase(collection: string, id: string, payload) {
    this.firebaseApp
      .firestore()
      .collection(collection)
      .doc(id)
      .set({
        ...JSON.parse(JSON.stringify(payload)),
      });
  }

  public getDoc(
    collection: FirebaseCollections,
    id: string,
    extraParams: IFirebaseReadFromCollectionParams = {}
  ) {
    // Get the reference
    const documentReference: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> =
      this.firebaseApp.firestore().collection(collection).doc(id);

    // Retrieve the document
    return from(documentReference.get()).pipe(
      map((doc) => {
        // check if should include the id
        if (extraParams.injectId) {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }

        return doc.data();
      })
    );
  }

  public setDoc(collection: FirebaseCollections, id: string, data: any) {
    // Get the reference
    const documentReference: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> =
      this.firebaseApp.firestore().collection(collection).doc(id);

    return from(documentReference.set(data));
  }

  public getFirebaseToken(): Observable<string> {
    if (this.firebaseApp.auth().currentUser) {
      return from(this.firebaseApp.auth().currentUser.getIdToken());
    }

    return of(null);
  }

  public collection(
    collection: string
  ): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
    return this.firebaseApp.firestore().collection(collection);
  }

  public subscribeToDocument(
    collection: string,
    id: string
  ): Observable<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> {
    const returnedObservable = new Observable(
      (
        subscriber: Subscriber<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>>
      ) => {
        const userId: string = this.getCurrentUser().uid;

        const unsubscribe: () => void = this.firebaseApp
          .firestore()
          .collection(collection)
          .doc(id)
          .onSnapshot(
            (docSnapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => {
              subscriber.next(docSnapshot);
            }
          );

        this.subscriptions[userId].push(unsubscribe);
      }
    );

    return returnedObservable;
  }

  private initFirebaseIfNeeded() {
    if (this.firebaseApp) {
      return this.firebaseApp;
    }

    return firebase.initializeApp(firebaseConfig);
  }

  private cleanSubscriptions() {
    const userId: string = this.getCurrentUser().uid;

    for (const unsubscribe of this.subscriptions[userId]) {
      unsubscribe();
    }

    this.subscriptions[userId] = [];
  }
}
