export const getPhoneNumberArray = (phoneNumber: string): string[] => {
  if (!phoneNumber) {
    return null;
  }

  const hasPrefix: boolean = /\+[0-9]{2} /.test(phoneNumber);
  let phoneNumberArray: string[] = [];

  if (hasPrefix) {
    phoneNumberArray = phoneNumber?.split(' ', 2);
  } else {
    phoneNumberArray = ['', phoneNumber];
  }

  return phoneNumberArray;
};
